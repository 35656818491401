import React, { useRef, useState } from 'react'
import { DashboardHomeRoutes } from '../../../Routes/dashboard-home';
import { NavLink } from 'react-router-dom';

export const DashboardHomePage = () => {

    const sidemenuData = [
        {
            id: 0,
            name: "Portfolios",
            isActive: false,
            route: '/dashboard/home/portfolio'
        },
        {
            id: 1,
            name: "Campaigns",
            isActive: true,
            route: '/dashboard/home/campaigns'
        }
    ]

    return (
        <div>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-3 col-md-1 px-0 dashboard-side-menu-container campaign-creation-bg">
                        {sidemenuData?.map((menu: any, index: number) => (
                            <NavLink to={menu.route} className='text-decoration-none text-dark' key={index}>
                                <li className={`fs-13 `}>
                                    {menu.name}
                                </li>
                            </NavLink>
                        ))}
                    </div>
                    <div className="col-9 col-md-11">
                        <DashboardHomeRoutes />
                    </div>
                </div>
            </div>
        </div>
    )
}