import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { AdGroupInfoRoutes } from '../../../../Routes/ad-group-info'
import { Breadcum } from '../../../../components/breadcum';
import { toast } from "react-toastify";
import { GET_SPECIFIC_AD_GROUP } from '../../../../app/services/adgroups.service';
import { connect } from 'react-redux';
import { GET_USER_CAMPAIGNS } from '../../../../app/services/campaigns.service';

interface Props {
    CampaignDataReducer?: any;
    campaignData?: any;
    AdGroupSettingsDataReducer?: any;
    adgroupsSettingsData?: any;
}

const AdGroupInfoPage = (props: Props) => {
    const [activeStatus, setActiveStatus] = useState<boolean>(false);
    const [adGroupDataByID, setAdGroupDataByID] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const currentDate = new Date().toISOString().split('T')[0];

    const sidemenuData = [
        {
            id: 1,
            name: "Ads",
            isActive: false,
            route: '/dashboard/ad-groups/ads'
        },
        {
            id: 2,
            name: "Targeting",
            isActive: false,
            route: '/dashboard/ad-groups/targeting'
        },
        {
            id: 3,
            name: "Negative targeting",
            isActive: false,
            route: '/dashboard/ad-groups/negative-targeting'
        },
        {
            id: 4,
            name: "Search terms",
            isActive: false,
            route: '/dashboard/ad-groups/search-terms'
        },
        {
            id: 5,
            name: "Ad group settings",
            isActive: true,
            route: '/dashboard/ad-groups/ad-group-settings'
        }
    ]

    const [breadcumInfo, setBreadcumInfo] = useState<any>([
        {
            label: "All Campaigns",
            value: "Campaign",
            routerLink: "/dashboard/home/campaigns",
            isActive: false,
        },
        {
            label: "Campaign Name",
            value: "Campaign : ",
            routerLink: "/dashboard/campaign/ad-groups",
            isActive: false,
        },
        {
            label: "Ad Groups",
            value: "Ad Groups",
            routerLink: "/dashboard/campaign/ad-groups",
            isActive: false,
        },
        {
            label: "Ad Group Name",
            value: "Ad Group : Ad Group One",
            routerLink: "/dashboard/ad-groups/ads",
            isActive: true,
        },
    ]);

    useEffect(() => {
        const adGRoupID: any = localStorage.getItem("ad_group_id");
        getAdGroupById(adGRoupID);
        const campaignID: any = localStorage.getItem("campaign_id");
        getCampaignInfoById(campaignID);
    }, [])

    useEffect(() => {
        if (props?.adgroupsSettingsData) {
            const adGRoupID: any = localStorage.getItem("ad_group_id");
            getAdGroupById(adGRoupID);
        }
    }, [props?.adgroupsSettingsData])


    const getAdGroupById = (adGroupID: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupID,
            is_report_data: false
        }
        setLoading(true);
        GET_SPECIFIC_AD_GROUP(preparedData).then((res) => {
            if (res?.data?.status === "error" || res?.data?.code == 500) {
                toast.error(res?.data?.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    response?.data?.forEach((element: any) => {
                        element.campaign_state = props?.campaignData?.state;
                        element.campaign_start_date = props?.campaignData?.start_date;
                    });
                    setAdGroupDataByID(response?.data[0])
                    getBreadCumList(response?.data[0]);
                    const adGroupStatus = response?.data[0]?.state
                    if (adGroupStatus === "ENABLED") {
                        setActiveStatus(true);
                    } else {
                        setActiveStatus(false);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getCampaignInfoById = (campaignID: any) => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            is_report_data: false,
        }
        setLoading(true);
        GET_USER_CAMPAIGNS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    const data = breadcumInfo;
                    data[1].value = `Campaign : ${response?.data?.data[0].campaign_name}`
                    setBreadcumInfo([...data])
                    const status = response?.data?.data[0]?.state;
                    if (status === "ENABLED") {
                        setActiveStatus(true);
                    } else {
                        setActiveStatus(false);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getBreadCumList = (data: any) => {
        const adGroupData: any = breadcumInfo;
        adGroupData[3].value = data?.ad_group_name
        adGroupData[3].isActive = true
        setBreadcumInfo([...adGroupData])
    }

    const handleActiveChanges = (e: any) => {
        const status = e.target.checked ? true : false;
        setActiveStatus(status);
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading &&
                    <div className='sub-header-container dashboard-side-menu-container border-bottom py-2'>
                        <div className='fs-28'>Ad Group : <span className='fs-28 fw-bold'>{adGroupDataByID?.ad_group_name}</span></div>
                        <div className='d-md-flex my-2'>
                            <div>
                                <div className="form-check form-switch ps-1">
                                    <label className="switch text-start mt-1">
                                        <input type="checkbox" name="toogle" defaultChecked={activeStatus} onChange={(e) => handleActiveChanges(e)} />
                                        <div className="slider round ">
                                            <span className="on">
                                                <svg viewBox="0 0 512 512" width="7" height="7" className="sc-storm-ui-20033309__sc-1gsqp3h-0 eYdsHI fas fa-check toggle-check-mark-position" data-fa-i2svg="">
                                                    <path fill="white" d="M488.44 83.42a53.15 53.15 0 0 1 0 75.15L202.86 444.15l-179.3-179.3a53.14 53.14 0 0 1 75.16-75.15l104.14 104.14L413.28 83.42a53.15 53.15 0 0 1 75.16 0Z">
                                                    </path>
                                                </svg>
                                            </span>
                                            <span className="off"></span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className='fs-13 my-1 mx-1 ms-2'><span className="fw-600">Status:</span> <span className={`${adGroupDataByID?.campaign_state === "ENABLED" ? "text-success" : "text-dakr"}`}>{adGroupDataByID?.campaign_state === "ENABLED" ? adGroupDataByID?.campaign_start_date > currentDate ? "Scheduled" : "Delivering" : "Paused"}</span>
                            </div>
                        </div>
                        <div className='ps-2'>
                            <Breadcum breadcumList={breadcumInfo}></Breadcum>
                        </div>
                    </div>
                }
            </div>
            <div className='row d-flex'>
                <div className='dashboard-side-menu-container campaign-creation-bg' style={{width:'12%'}}>
                    {sidemenuData?.map((menu: any, index: number) => (
                        <NavLink to={menu.route} className='text-decoration-none text-dark'>
                            <li key={index} className={`fs-13`}>
                                {menu.name}
                            </li>
                        </NavLink>
                    ))}
                </div>
                <div className='' style={{width:'88%'}}>
                    <AdGroupInfoRoutes />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer,
        adgroupsSettingsData: state.AdGroupSettingsDataReducer,
    };
};

const connectedNavBar = connect(mapStateToProps)(AdGroupInfoPage);
export { connectedNavBar as AdGroupInfoPage };
