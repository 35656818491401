import { CustomTableCol } from "../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const AdGroupsDataGridCols: CustomTableCol[] = [
  // {
  //   title: "AI Actions",
  //   control: "actions",
  //   sortable: false,
  //   canShowColumn: true,
  //   isView: true,
  //   class: "col_table",
  //   style: { width: "90px", left: "0px", height: "45px" },
  // },
  {
    title: "Active",
    control: "state",
    sortable: false,
    canShowColumn: true,
    class: "col_table",
    style: { width: "70px", left: "0px", height: "45px" },
    isShowSwitch: true,
  },
  {
    title: "Ad Group",
    control: "ad_group_name",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "360px", left: "70px", height: "45px" },
    icon: info,
    isLink: true,
    infoIcon: true,
    tooltip: {
      text: "Ad Group adgroups",
    },
  },
  {
    title: "Status",
    control: "campaign_status",
    sortable: true,
    canShowColumn: true,
    class: "col_table",
    style: { width: "95px", left: "400px", height: "45px" },
  },
  {
    title: "Default bid",
    control: "default_bid",
    sortable: true,
    canShowColumn: true,
    style: { width: "130px", height: "45px" },
    infoIcon: true,
    tooltip: {
      text: "Ad Group default bid",
    },
  },
  {
    title: "Impressions",
    control: "impressions",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px", height: "45px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Impressions"
    },
  },
  {
    title: "Top-of-search IS",
    control: "topOfSearchImpressionShare",
    sortable: true,
    canShowColumn: true,
    style: { width: "150px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Top of Search js"
    },
  },
  {
    title: "Clicks",
    control: "clicks",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Clicks"
    },
  },
  {
    title: "CTR",
    control: "clickThroughRate",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "CTR"
    },
  },
  {
    title: "Spend ",
    control: "cost",
    sortable: true,
    canShowColumn: true,
    style: { width: "140px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Spend"
    },
  },
  {
    title: "CPC",
    control: "costPerClick",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns CPC"
    },
  },
  {
    title: "Orders",
    control: "purchases30d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Orders"
    },
  },
  {
    title: "Sales",
    control: "sales30d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns Sales"
    },
  },
  {
    title: "ACOS",
    control: "acosClicks14d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Ad Group ACOS"
    },
  },
  {
    title: "ROAS",
    control: "roasClicks14d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ROAS"
    },
  },
  {
    title: "Conversion rate",
    control: "conversion_rate",
    sortable: true,
    canShowColumn: true,
    style: { width: "120px" },
    icon: info,
  },
  {
    title: "Total units",
    control: "unitsSoldClicks1d",
    sortable: true,
    canShowColumn: true,
    style: { width: "100px" },
    icon: info,
  },
];
