import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-datepicker/dist/react-datepicker.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

interface Props {
    showChartStatus?: any;
    customCreateButtonText?: string;
    customPlaceholder?: string;
    customDateRangeText?: string;
    placementsFilter?: boolean;
    negativeKeywordFilter?: boolean;
    adGroupsNegativeKeywordFilter?: boolean;
    adGroupHistoryFilter?: boolean;
    customPortfolioEditFilter?: boolean;
    portfolioAddCampaignsModalFilter?: boolean;
    searchTermFilter?: boolean;
    budgetRuleFilter?: boolean;
    campaignsFilter?: boolean;
    campaignsDateRaneFilter?: boolean;
    exportData?: any;
    exportTableData?: any;
    historyFilter?: boolean;
    campaignFilter?: boolean;
    showAdGroupAdsDateRangeFilter?: boolean;
    showAdGroupDateRangeFilter?: any;
    showPortfolioDateRangeFilter?: any;
    showAdgroupTargetingDateRangeFilter?: any;
    onClickButton?: (data: any) => void;
    onClickDotsButton?: (data: any) => void;
    handleCustomSearch?: (data: any) => void;
    handleClickSearchWrong?: (data: any) => void;
    camapignSearchData?: (data: any) => void;
    handleCustomFilter?: (data: any) => void;
    dateRangeFilterData?: (data: any) => void;
    onRefreshCampaignsData?: (data: any) => void;
    campaignExport?: (data: any) => void;
    dateRangeData?: any;
    exportLoadingStatus?: any;
    portfolioExportFilter?: any;
    adGroupNegativeTargetingExportFilter?: any;
    searchTermExportFilter?: any;
    portfolioCampaignsExport?: any;
    campaignNegativeTargetingExport?: any;
}

export const CustomFilter = (props: Props) => {
    const [selectedCustomColumn, setSelectedCustomColumn] = useState<any>('All')
    const [showChartstatus, setShowChartstatus] = useState<boolean>(true);
    const [showCustomiseColumns, setShowCustomiseColumns] = useState<boolean>(false);
    const [showWrongIcon, setShowWrongIcon] = useState<boolean>(false);
    const datepickerRef: any = useRef(null);
    const [searchValue, setSearchValue] = useState<any>();
    const modalRef: any = useRef(null);
    const [activeFilterType, setActiveFilterType] = useState<any>();
    const [activeFilterApplyButtonStatus, setActiveFilterApplyButtonStatus] = useState<boolean>(false);
    const [showActiveStatusFilterModal, setShowActiveStatusFilterModal] = useState<boolean>(false);
    const currentDate = new Date();
    let defaultStartDate: any = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    let defaultEndDate: any = currentDate;
    const [start, setStartDate] = useState(defaultStartDate);
    const [end, setEndDate] = useState(defaultEndDate);

    const customCustomiseColumnsData = [
        {
            name: "All",
            value: "All"
        },
        {
            name: "Settings",
            value: "Settings"
        },
        {
            name: "Performance",
            value: "Performance"
        },
        {
            name: "Conversions",
            value: "Conversions"
        },
        {
            name: "Video",
            value: "Video"
        },
    ]

    const customiseColumnsData = [
        {
            name: "Budget",
            value: "Budget"
        },
        {
            name: "Budget start",
            value: "Budget start"
        },
        {
            name: "Budget end",
            value: "Budget end"
        },
        {
            name: "Impressions",
            value: "Impressions"
        },
        {
            name: "Clicks",
            value: "Clicks"
        },
        {
            name: "Click-through rate (CTR)",
            value: "Click-through rate (CTR)"
        },
        {
            name: "Spend",
            value: "Spend"
        },
        {
            name: "Cost-per-click (CPC)",
            value: "Cost-per-click (CPC)"
        },
        {
            name: "Orders",
            value: "Orders"
        },
        {
            name: "Sales",
            value: "Sales"
        },
        {
            name: "Advertising cost of sales (ACOS)",
            value: "Advertising cost of sales (ACOS)"
        },
        {
            name: "Return on ad spend (ROAS)",
            value: "Return on ad spend (ROAS)"
        },
        {
            name: "New-to-brand (NTB) orders",
            value: "New-to-brand (NTB) orders"
        },
        {
            name: "% of orders new-to-brand (NTB)",
            value: "% of orders new-to-brand (NTB)"
        },
        {
            name: "New-to-brand (NTB) sales",
            value: "New-to-brand (NTB) sales"
        },
        {
            name: "% of sales new-to-brand(NTB)",
            value: "% of sales new-to-brand(NTB)"
        }
    ]

    const activeStautsFilterData = [
        {
            name: "All",
            value: "all"
        },
        {
            name: "Enabled",
            value: "enabled"
        },
        {
            name: "Paused",
            value: "paused"
        },
        {
            name: "Archived",
            value: "archived"
        },
    ]

    useEffect(() => {
        setStartDate(start);
        setEndDate(end);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowCustomiseColumns(false);
            }
        };
        const handleEscapeKey = (event: any) => {
            if (event.key === 'Escape') {
                setShowCustomiseColumns(false);
            }
        };
        if (showCustomiseColumns) {
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleEscapeKey);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [showCustomiseColumns]);

    const handleShowChartStatus = () => {
        setShowChartstatus(!showChartstatus);
        props.showChartStatus(!showChartstatus);
    }

    const onClickCreatebutton = (data: any) => {
        if (props.onClickButton) {
            props.onClickButton({ buttonText: data })
        }
    }

    const handleChangeCustomiseColumn = (option: string) => {
        setSelectedCustomColumn(option);
    }

    const onChangeSearchInputValue = (e: any) => {
        const searchTerm = e.target.value;
        setSearchValue(searchTerm);
        if (searchTerm?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        }
        setShowWrongIcon(false)
    }

    const onClickSearch = () => {
        if (props?.handleCustomSearch) {
            props?.handleCustomSearch(searchValue)
        }
        if (searchValue?.trim()?.length > 0) {
            setShowWrongIcon(true)
        } else if (searchValue?.trim()?.length <= 0) {
            setShowWrongIcon(false)
        }
    }
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            onClickSearch();
        }
    };

    const onClickWrongIcons = () => {
        setSearchValue("");
        setShowWrongIcon(false);
        if (props?.handleClickSearchWrong) {
            props?.handleClickSearchWrong(false);
        }
        if (props?.handleCustomSearch) {
            props?.handleCustomSearch('')
        }
    }

    const handleChangeActiveStatusFilter = (selectedValue: any) => {
        setActiveFilterType(selectedValue);
        setActiveFilterApplyButtonStatus(true);
    }

    const onClickFilterApply = () => {
        if (props?.handleCustomFilter) {
            setShowActiveStatusFilterModal(false)
            props?.handleCustomFilter(activeFilterType)
        }
    }

    const handleDateChange = (dates: any) => {
        props.dateRangeData.end_date = '';
        props.dateRangeData.start_date = '';
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            if (start && end) {
                const payload: any = {
                    start_date: moment(start).format("YYYY-MM-DD"),
                    end_date: moment(end).format("YYYY-MM-DD")
                }
                const updatedPayload = JSON.stringify(payload);
                localStorage.setItem('updated_date_range', updatedPayload)
                if (props.dateRangeFilterData) {
                    props.dateRangeFilterData(payload)
                }
            } else {
                const payload: any = {
                    start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                    end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                };
                const updatedDefaultPayload = JSON.stringify(payload);
                localStorage.setItem('default_date_range', updatedDefaultPayload)
                if (props.dateRangeFilterData) {
                    props.dateRangeFilterData(payload);
                }
            }
        }
    }

    const onClickExportData = () => {
        const data = props?.exportData;
        if (props?.campaignExport) {
            props?.campaignExport(true)
        }
        if (data === "campaigns") {
            const tableData: any[] = props?.exportTableData?.data || [];
            const csvData = tableData?.map((e: any) => {
                const consent_data = {
                    "Campaign": e?.campaign_name,
                    "State": e?.state,
                    "Type": e?.targeting_type,
                    "Start date": e?.start_date ? e?.start_date : "-",
                    "End date": e?.end_date ? e?.end_date : "-",
                    "Budget": e?.budget,
                    "Impressions": e?.metricsData?.impressions ? e?.metricsData?.impressions : "-",
                    "Top-of-search IS": e?.metricsData?.topOfSearchImpressionShare ? e?.metricsData?.topOfSearchImpressionShare?.toFixed(2) : "-",
                    "Clicks": e?.metricsData?.clicks ? e?.metricsData?.clicks : "-",
                    "CTR": e?.metricsData?.clickThroughRate ? e?.metricsData?.clickThroughRate?.toFixed(2) : "-",
                    "Spend ": e?.metricsData?.cost ? e?.metricsData?.cost?.toFixed(2) : "-",
                    "CPC": e?.metricsData?.costPerClick ? e?.metricsData?.costPerClick : "-",
                    "Orders": e?.metricsData?.purchases30d ? e?.metricsData?.purchases30d : "-",
                    "Sales": e?.metricsData?.sales30d ? e?.metricsData?.sales30d : "-",
                    "ACOS": e?.metricsData?.acosClicks14d ? e?.metricsData?.acosClicks14d : "-",
                    "ROAS": e?.metricsData?.roasClicks14d ? e?.metricsData?.roasClicks14d : "-",
                    "Conversion rate": e?.metricsData?.conversion_rate ? e?.metricsData?.conversion_rate : "-",
                    "Total units": e?.metricsData?.unitsSoldClicks1d ? e?.metricsData?.unitsSoldClicks1d : "-",
                    "Benchmark": e?.benchmark ? e?.benchmark : "-",
                    "Qualifier": e?.qualifier ? e?.qualifier : "-"
                };
                return consent_data;
            });

        } else if (data === "ad_groups") {
            const tableData: any[] = props?.exportTableData?.rows || [];
            const csvData = tableData.map((e: any) => {
                const consent_data = {
                    "Ad group": e?.ad_group_name,
                    "State": e?.state,
                    "Default bid": e?.default_bid,
                    "Impressions": e?.metricsData?.impressions ? e?.metricsData?.impressions : "-",
                    "Top-of-search IS": e?.metricsData?.topOfSearchImpressionShare ? e?.metricsData?.topOfSearchImpressionShare?.toFixed(2) : "-",
                    "Clicks": e?.metricsData?.clicks ? e?.metricsData?.clicks : "-",
                    "CTR": e?.metricsData?.clickThroughRate ? e?.metricsData?.clickThroughRate?.toFixed(2) : "-",
                    "Spend ": e?.metricsData?.cost ? e?.metricsData?.cost?.toFixed(2) : "-",
                    "CPC": e?.metricsData?.costPerClick ? e?.metricsData?.costPerClick : "-",
                    "Orders": e?.metricsData?.purchases30d ? e?.metricsData?.purchases30d : "-",
                    "Sales": e?.metricsData?.sales30d ? e?.metricsData?.sales30d : "-",
                    "ACOS": e?.metricsData?.acosClicks14d ? e?.metricsData?.acosClicks14d : "-",
                    "ROAS": e?.metricsData?.roasClicks14d ? e?.metricsData?.roasClicks14d : "-",
                    "Conversion rate": e?.metricsData?.conversion_rate ? e?.metricsData?.conversion_rate : "-",
                    "Total units": e?.metricsData?.unitsSoldClicks1d ? e?.metricsData?.unitsSoldClicks1d : "-"
                };
                return consent_data;
            });

            let headers = Object.keys(csvData[0] || {});
            let str = [
                headers.join(","),
                ...csvData.map((row: any) =>
                    headers
                        .map((fieldName) =>
                            JSON.stringify(row[fieldName])
                        )
                        .join(",")
                ),
            ].join("\r\n");

            let exportedFilename = "AdGroups.csv";
            let blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
            let downloadURL = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = downloadURL;
            link.download = exportedFilename;
            link.click();
            window.URL.revokeObjectURL(downloadURL);
        } else if (data === "Targetings") {
            const tableData: any[] = props?.exportTableData?.rows || [];
            const csvData = tableData.map((e: any) => {
                const consent_data = {
                    "Keyword": e?.keyword_text,
                    "State": e?.state,
                    "Match Type": e?.match_type,
                    "Bid": e?.bid,
                    "Impressions": e?.metricsData?.impressions ? e?.metricsData?.impressions : "-",
                    "Top-of-search IS": e?.metricsData?.topOfSearchImpressionShare ? e?.metricsData?.topOfSearchImpressionShare?.toFixed(2) : "-",
                    "Clicks": e?.metricsData?.clicks ? e?.metricsData?.clicks : "-",
                    "CTR": e?.metricsData?.clickThroughRate ? e?.metricsData?.clickThroughRate?.toFixed(2) : "-",
                    "Spend ": e?.metricsData?.cost ? e?.metricsData?.cost?.toFixed(2) : "-",
                    "CPC": e?.metricsData?.costPerClick ? e?.metricsData?.costPerClick : "-",
                    "Orders": e?.metricsData?.purchases30d ? e?.metricsData?.purchases30d : "-",
                    "Sales": e?.metricsData?.sales30d ? e?.metricsData?.sales30d : "-",
                    "ACOS": e?.metricsData?.acosClicks14d ? e?.metricsData?.acosClicks14d : "-",
                    "ROAS": e?.metricsData?.roasClicks14d ? e?.metricsData?.roasClicks14d : "-",
                    "Conversion rate": e?.metricsData?.conversion_rate ? e?.metricsData?.conversion_rate : "-",
                    "Total units": e?.metricsData?.unitsSoldClicks1d ? e?.metricsData?.unitsSoldClicks1d : "-",
                    "Benchmark": e?.benchmark ? e?.benchmark : "-",
                    "Qualifier": e?.qualifier ? e?.qualifier : "-"
                };
                return consent_data;
            });

            let headers = Object.keys(csvData[0] || {});
            let str = [
                headers.join(","),
                ...csvData.map((row: any) =>
                    headers
                        .map((fieldName) =>
                            JSON.stringify(row[fieldName])
                        )
                        .join(",")
                ),
            ].join("\r\n");

            let exportedFilename = "Targetings.csv";
            let blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
            let downloadURL = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = downloadURL;
            link.download = exportedFilename;
            link.click();
            window.URL.revokeObjectURL(downloadURL);
        } else if (data === "Product Ads") {
            const tableData: any[] = props?.exportTableData?.rows || [];
            const csvData = tableData?.map((el: any) => {
                const product_ad_data =
                {
                    "Ad Title": el?.title,
                    "State": el?.state,
                    "Sku": el?.sku,
                    "Asin": el?.asin,
                    "Impressions": el?.metricsData?.impressions ? el?.metricsData?.impressions : "-",
                    "Top-of-search IS": el?.metricsData?.topOfSearchImpressionShare ? el?.metricsData?.topOfSearchImpressionShare?.toFixed(2) : "-",
                    "Clicks": el?.metricsData?.clicks ? el?.metricsData?.clicks : "-",
                    "CTR": el?.metricsData?.clickThroughRate ? el?.metricsData?.clickThroughRate?.toFixed(2) : "-",
                    "Spend ": el?.metricsData?.cost ? el?.metricsData?.cost?.toFixed(2) : "-",
                    "CPC": el?.metricsData?.costPerClick ? el?.metricsData?.costPerClick : "-",
                    "Orders": el?.metricsData?.purchases30d ? el?.metricsData?.purchases30d : "-",
                    "Sales": el?.metricsData?.sales30d ? el?.metricsData?.sales30d : "-",
                    "ACOS": el?.metricsData?.acosClicks14d ? el?.metricsData?.acosClicks14d : "-",
                    "ROAS": el?.metricsData?.roasClicks14d ? el?.metricsData?.roasClicks14d : "-",
                    "Conversion rate": el?.metricsData?.conversion_rate ? el?.metricsData?.conversion_rate : "-",
                    "Total units": el?.metricsData?.unitsSoldClicks1d ? el?.metricsData?.unitsSoldClicks1d : "-",
                    "Benchmark": el?.benchmark ? el?.benchmark : "-",
                    "Qualifier": el?.qualifier ? el?.qualifier : "-"
                }
                return product_ad_data;
            })
            let headers = Object.keys(csvData[0] || {});
            let str = [
                headers.join(","),
                ...csvData.map((row: any) =>
                    headers
                        .map((fieldName) =>
                            JSON.stringify(row[fieldName])
                        )
                        .join(",")
                ),
            ].join("\r\n");

            let exportedFilename = "Product Ads.csv";
            let blob = new Blob([str], { type: "text/csv;charset=utf-8;" });
            let downloadURL = window.URL.createObjectURL(blob);
            let link = document.createElement("a");
            link.href = downloadURL;
            link.download = exportedFilename;
            link.click();
            window.URL.revokeObjectURL(downloadURL);
        } else { }
    }
    return (
        <div className="conatiner-fluid">
            <div className={`row filters-background border py-2 ${props?.campaignFilter || props?.campaignsFilter ? "mx-0" : "mx-1"}`}>
                <div className='col-12'>
                    <div className="row d-flex justify-content-between">
                        <div className='col-xl-6'>
                            {!props?.placementsFilter &&
                                <div className="d-sm-flex">
                                    {props?.budgetRuleFilter && (
                                        <div className='error-sub-title-text-color text-secondary fs-13 mt-1 me-2 ms-2 ms-md-0'>Bulk actions <span className='ms-1'><i className="bi bi-chevron-down"></i></span></div>
                                    )}
                                    {!props.historyFilter && !props?.adGroupHistoryFilter && !props?.searchTermFilter && !props?.customPortfolioEditFilter && !props?.portfolioAddCampaignsModalFilter && props?.customCreateButtonText && (
                                        <div className="fs-13 my-2 my-sm-0 ms-2 ms-md-0">
                                            <button className='request-call-button custom-create-btn text-white fs-13 px-3' onClick={() => onClickCreatebutton(props?.customCreateButtonText)}>{props?.customCreateButtonText ? props?.customCreateButtonText : ""}</button>
                                        </div>
                                    )}
                                    {props?.customPortfolioEditFilter && (
                                        <div className="fs-13 my-2 my-sm-0 ms-2 ms-md-0 d-flex">
                                            <button className='request-call-button custom-create-btn  text-white fs-13 px-3 py-1' onClick={() => onClickCreatebutton(props?.customCreateButtonText)}>{props?.customCreateButtonText}</button>
                                            <div className="dropdown d-none">
                                            </div>
                                        </div>
                                    )}
                                    {!props?.budgetRuleFilter && !props?.historyFilter && !props?.adGroupHistoryFilter && (
                                        <div className="input-group border-dark drop-down-input find-a-portfolio mx-2 mt-0 mb-2 mb-sm-0">
                                            <input type="text" name="custom_search" className={`form-control  drop-down-input  border-dark fs-13 border-end-0 px-1 ${showWrongIcon ? "border-end-0" : ""}`}
                                                placeholder={`${props?.customPlaceholder ? props?.customPlaceholder : "Find a portflio"}`}
                                                value={searchValue} onChange={onChangeSearchInputValue} onKeyPress={handleKeyPress} />
                                            {showWrongIcon && (
                                                <span className="input-group-text cursor-pointer input  drop-down-input bg-white border-dark  px-1"><i className="bi bi-x text-dark fw-bold cursor-pointer text-primary" onClick={onClickWrongIcons}></i></span>
                                            )}
                                            {!showWrongIcon &&
                                                <span className="input-group-text cursor-pointer bg-white input border-dark  drop-down-input  px-1" id="basic-addon1" onClick={onClickSearch}>
                                                    <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
                                                </span>
                                            }
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        <div className='col-xl-6'>
                            {!props?.budgetRuleFilter && !props?.portfolioAddCampaignsModalFilter && (
                                <div className="d-md-flex justify-content-end my-1 my-xl-0">
                                    {!props?.campaignsFilter && !props.placementsFilter && !props.negativeKeywordFilter && !props?.adGroupsNegativeKeywordFilter && !props?.searchTermFilter &&
                                        <div className='fs-12 custom-explore-text-color my-2 py-0 logout cursor-pointer ms-2 ms-md-0' onClick={handleShowChartStatus}>
                                            {!props?.campaignFilter && (<>{showChartstatus ? "Hide Chart" : "Show Chart"}</>)}
                                        </div>
                                    }
                                    {(props?.campaignsDateRaneFilter || props?.showAdGroupAdsDateRangeFilter || props?.showAdGroupDateRangeFilter
                                        || props?.showPortfolioDateRangeFilter || props?.showAdgroupTargetingDateRangeFilter
                                    ) && (!props.negativeKeywordFilter && !props?.adGroupsNegativeKeywordFilter) && (
                                            <div className='d-flex px-2'>
                                                <div ref={datepickerRef} className='col-md-12 d-flex custom-button-background rounded-pill fs-13 cursor-pointer text-end text-md-start mb-1 px-2'>
                                                    <div className='me-1 fs-13 d-flex align-items-center'>Date range - </div>
                                                    <div className='py-0'>
                                                        <DatePicker selectsRange
                                                            className="rounded border-0 fs-13 py-1 custom-button-background"
                                                            placeholderText=" Select Date Range"
                                                            startDate={props?.dateRangeData?.start_date ? moment(props?.dateRangeData?.start_date).toDate() : start}
                                                            endDate={props?.dateRangeData?.end_date ? moment(props?.dateRangeData?.end_date).toDate() : end}
                                                            onChange={(event: any) => handleDateChange(event)}
                                                            maxDate={currentDate}
                                                            monthsShown={2}
                                                            isClearble={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    {!props.historyFilter && !props?.adGroupHistoryFilter && !props?.portfolioExportFilter &&
                                        !props?.adGroupNegativeTargetingExportFilter && !props?.searchTermExportFilter && !props?.portfolioCampaignsExport && !props?.campaignNegativeTargetingExport && (
                                            <div>
                                                <button disabled={props?.exportLoadingStatus} className='btn btn-dark border-0 rounded-pill text-dark custom-button-background fs-13 cursor-pointer px-3  ms-2 ms-md-2 custom-export-button d-flex align-items-center' style={{ padding: "2px 10px" }} onClick={onClickExportData}>
                                                    {props?.exportLoadingStatus ?
                                                        <>  <span>exporting</span>
                                                            <span className='ms-1 mt-1'>
                                                                {props?.exportLoadingStatus &&
                                                                    <div className="text-center">
                                                                        <div className="spinner-border export-spinner" role="status">
                                                                            <span className="sr-only"></span>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </span> </> : <><span>export</span><span><i className="bi bi-cloud-arrow-down-fill text-secondary fs-18 ms-1"></i></span></>}
                                                </button>
                                            </div>
                                        )}
                                </div>
                            )}
                            {props?.portfolioAddCampaignsModalFilter && (
                                <div className='d-md-flex justify-content-end my-1 my-xl-0'>
                                    <div className='d-flex justify-content-between'>
                                        <div className="fs-14 add-products-text-color">0 campaigns <span className='mx-2'>|</span></div>
                                        <div className="fs-14 add-products-text-color cursor-pointer-not-allowed bg-transparent">Show selected</div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {showCustomiseColumns && (
                        <div className={`modal modal-custom fade ${showCustomiseColumns ? "show" : "false"}  ${showCustomiseColumns ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal-lg modal-dialog-centered" role="document" ref={modalRef} >
                                <div className="modal-content customise-columns-modal-content mx-auto border-bottom-0 my-auto ">
                                    <div className="modal-body modal-profolio-body">
                                        <div className="row">
                                            <div className="col-4 border-end border-dark py-2 customize-left-dropdown">
                                                {customCustomiseColumnsData?.map((customiseColumns: any, index: number) => (
                                                    <li key={index} className={`list-style-type-none cusror-pointer py-1 px-2 ${selectedCustomColumn === customiseColumns?.name ? "active" : ""}`} defaultValue={``} onClick={() => handleChangeCustomiseColumn(customiseColumns?.name)}>
                                                        {customiseColumns?.name}
                                                    </li>
                                                ))}
                                                <ul className="dropdown-menu newest-first-dropdown columns-dropdown">
                                                    <li className='fs-13 py-1'>
                                                        {customCustomiseColumnsData?.map((item: any, index: number) => (
                                                            <button key={index} className={`drop-down-item border-0 bg-transparent text-start py-1 ${selectedCustomColumn === item?.value ? "active" : ""}`} value={item.value} onClick={() => handleChangeCustomiseColumn(item?.value)}>
                                                                <div className='fw-400'>{item.name}</div>
                                                            </button>
                                                        ))}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-8 py-2">
                                                <div className='d-flex justify-content-between'>
                                                    <div className='fs-15 fw-bold'>
                                                        Available metrics
                                                    </div>
                                                    <div className='fs-15 custom-explore-text-color logout cursor-pointer'>
                                                        Select all
                                                    </div>
                                                </div>
                                                <div className="input-group border-dark drop-down-input my-1">
                                                    <span className="input-group-text bg-white input border-dark drop-down-input  px-1" id="basic-addon1">
                                                        <svg viewBox="0 0 512 512" width="13" height="13" className="fas fa-search search-icon-color"><path fill="rgb(130, 144, 164)" d="M194 8c102.73 0 186 83.28 186 186 0 40.17-12.73 77.37-34.39 107.77l149.31 149.31a31 31 0 0 1-43.84 43.84l-149.3-149.3A185.14 185.14 0 0 1 194 380C91.28 380 8 296.73 8 194 8 91.28 91.28 8 194 8Zm0 62c-68.48 0-124 55.52-124 124 0 68.48 55.52 124 124 124 68.48 0 124-55.52 124-124 0-68.48-55.52-124-124-124Z"></path></svg>
                                                    </span>
                                                    <input type="text" name="custom_search" className={`form-control drop-down-input border-dark fs-13 border-start-0  px-0 ${showWrongIcon ? "border-end-0" : ""}`}
                                                        placeholder={`Quick search`}
                                                        value={searchValue} onChange={onChangeSearchInputValue} />
                                                    {showWrongIcon && (
                                                        <span className="input-group-text input drop-down-input bg-white border-dark  px-1"><i className="bi bi-x text-dark fw-bold cursor-pointer text-primary" onClick={onClickWrongIcons}></i></span>
                                                    )}
                                                </div>
                                                <div className="customise-columnslist border-top">
                                                    {customiseColumnsData?.map((data: any, index: number) => (
                                                        <div key={index} className="list-group coulmns-list-group-broder list-item">
                                                            <label className="list-group-item cursor-pointer">
                                                                <input className="form-check-input me-1" type="checkbox" value="" />
                                                                {data.name}
                                                            </label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                            onClick={() => setShowCustomiseColumns(false)}
                                        >
                                            Cancel
                                        </button>
                                        <button type="button" className="request-call-button text-white">
                                            apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showActiveStatusFilterModal && (
                        <div className={`modal modal-custom fade ${showActiveStatusFilterModal ? "show" : "false"}  ${showActiveStatusFilterModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal-lg modal-dialog-centered position-absolute" role="document" ref={modalRef} >
                                <div className="modal-content active-filter-modal-position customise-columns-modal-content mx-auto border-bottom-0 my-auto ">
                                    <div className="modal-body modal-profolio-body pt-2 pb-0">
                                        <div className='d-flex justify-content-between'>
                                            <div className="fs-17 fw-400">Acitve status</div>
                                            <div className="custom-explore-text-color-two logout cursor-pointer fs-13 my-1" onClick={() => setShowActiveStatusFilterModal(false)}>Remove</div>
                                        </div>
                                        <div className='mt-3'>
                                            {activeStautsFilterData?.map((data: any, index: number) => (
                                                <div key={index} className='form-check'>
                                                    <input type="radio" name="check_radio" id={`activeFilterStatus-${index}`} className='form-check-input' onChange={(e) => handleChangeActiveStatusFilter(data?.value)} />
                                                    <label className='form-label cursor-pointer' htmlFor={`activeFilterStatus-${index}`}>{data?.name}</label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="modal-footer border-top-0 pt-0 pb-3">
                                        <button type="button" className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                            onClick={() => setShowActiveStatusFilterModal(false)}>
                                            Cancel
                                        </button>
                                        <button type="button" className={`rounded-pill  px-3 py-1 ${activeFilterApplyButtonStatus ? "cursor-pointer request-call-button text-white" :
                                            "custom-button-background  border-0 text-secondarycursor-pointer-not-allowed"}`}
                                            disabled={!activeFilterApplyButtonStatus} onClick={onClickFilterApply}>
                                            apply
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
