import React, { useEffect, useState } from 'react'
import { CustomTable } from '../../../../components/custom-table'
import { AdGroupsDataGridCols } from './ad-groups-data-grid-cols'
import ChartComponent from '../../../../components/charts/line-chart'
import { TooltipPage } from '../../../../components/custom-tooltip/tooltipPage'
import { Tooltip } from '@mui/material';
import { CustomFilter } from '../../../../components/custom-filter/custom-filter';
import { useNavigate } from 'react-router-dom'
import PlusIcon from "../../../../assets/images/svg_icons/plus.svg";
import { CustomFooter } from '../../../../components/footer/custom-footer'
import { GET_CAMPAIGN_AD_GROUPS, UPDATE_AD_GROUP_STATUS } from '../../../../app/services/adgroups.service'
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { GET_AD_GROUP_SUMMARY_REPORT } from '../../../../app/services/portfolio-summary-report.service'
import moment from 'moment'
import { AiActionsTabs } from '../../../../components/ai-actions-tabs/ai-actions-tabs'

interface Props {
  ProfileDataReducer?: any;
  profileData?: any;
  UserDataReducer?: any;
  userData?: any;
  CampaignDataReducer?: any;
  campaignData?: any;
  UserUuidDataReducer?: any;
  userUuidData?: any;
}

const AdGroupsListPage = (props: Props) => {
  const [showChart, setShowChart] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const [tooltipClose, setTooltipClose] = useState<boolean>(false);
  const navigate = useNavigate();
  const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
  const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
  const [campaignAdGroupsData, setCampaignAdGroupsData] = useState<any>()
  const [originalCampaignAdGroupsData, setOriginalCampaignAdGroupsData] = useState<any>()
  const userUuid = localStorage.getItem('user_uuid');
  const profileID = localStorage.getItem('profile_id');
  const campaignID: any = localStorage.getItem("campaign_id");
  const [summaryReportsData, setSummaryReportsData] = useState<any>();
  const [ShowAdGroupAiActionsModal, setShowAdGroupAiActionsModal] = useState<boolean>(false);
  const [showAiRejectConfirmationModal, setShowAiRejectConfirmationModal] = useState<boolean>(false);
  const [aiRejectReasonData, setAiRejectReasonData] = useState<any>({
    rejection_message: ""
  })
  const [aiRejectFormErrors, setAiRejectFormErrors] = useState<any>({})
  const [selectedAdGroupData, setSelectedAdGroupData] = useState<any>([]);
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  const defaultEndDate = currentDate;

  const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
  const updatedData = JSON.parse(sessionUpdateDateRange);

  const [dateRangeData, setDateRangeData] = useState<any>({
    start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
    end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
  })

  const [grpahCardsData, setGrpahCardsData] = useState([
    {
      card_title: "Sales",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #ff5733",
      key: 'sales'
    },
    {
      card_title: "Spend",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #373782",
      key: 'cost'
    },
    {
      card_title: "ROAS",
      amount: ``,
      sub_title: "AVERAGE",
      border_color: "4px solid #fe4a4a",
      key: 'roas'
    },
    {
      card_title: "Impressions",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #4949da",
      key: 'impressions'
    },
  ]);

  const [metricCardsData, setMetricCardsData] = useState<any>([
    {
      card_title: "Sales",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #ff5733",
      key: 'sales'
    },
    {
      card_title: "Spend",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #373782",
      key: 'cost'
    },
    {
      card_title: "ROAS",
      amount: ``,
      sub_title: "AVERAGE",
      border_color: "4px solid #fe4a4a",
      key: 'roas'
    },
    {
      card_title: "Impressions",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #4949da",
      key: 'impressions'
    },
    {
      card_title: "CTR",
      amount: ``,
      sub_title: "AVERAGE",
      border_color: "4px solid #3e1bc2",
      key: 'clickThroughRate'
    },
    {
      card_title: "Clicks",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #301934",
      key: 'clicks'
    },
    {
      card_title: "CPC",
      amount: '',
      sub_title: "AVERAGE",
      border_color: "4px solid #1d2383",
      key: 'costPerClick'
    },
    {
      card_title: "Orders",
      amount: ``,
      sub_title: "TOTAL",
      border_color: "4px solid #53f453",
      key: 'orders'
    },
    {
      card_title: "ACOS",
      amount: ``,
      sub_title: "AVERAGE",
      border_color: "4px solid #ff1cff",
      key: 'acoss'
    },
  ]);

  useEffect(() => {
    const updatedDateRangeData: any = localStorage.getItem('updated_date_range')
    const dateRange: any = JSON.parse(updatedDateRangeData)
    if (dateRange) {
      const data: any = dateRangeData;
      data.start_date = dateRange?.start_date
      data.end_date = dateRange?.end_date
      setDateRangeData({ ...dateRangeData, start_date: dateRange?.start_date, end_date: dateRange?.end_date })
      getCampaignAdGroups(dateRange)
      getPortfolioSummaryReport(dateRange);
    } else {
      getCampaignAdGroups(dateRangeData);
      getPortfolioSummaryReport(dateRangeData);
    }
  }, [])

  const selectedGraphMetric = (data: any, index: number) => {
    setSelectedMetricIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const tableData = {
    page: "0",
    limit: "10",
    total: 14,
    rows: campaignAdGroupsData
  }

  const tablefooterData = [
    {
      ad_group_name: `Total: ${campaignAdGroupsData?.length ? campaignAdGroupsData?.length : 0}`,
      impressions: "-",
      topOfSearchImpressionShare: "-",
      clicks: "-",
      clickThroughRate: "-",
      cost: "-",
      costPerClick: "-",
      purchases30d: "-",
      sales30d: "-",
      acosClicks14d: "-",
      roasClicks14d: "-",
      conversion_rate: "-",
      unitsSoldClicks1d: "-"
    },
  ];

  const getCampaignAdGroups = (data: any) => {
    const sessionDateRange: any = localStorage?.getItem('updated_date_range')
    const dateRange = JSON.parse(sessionDateRange)
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileID,
      campaign_id: campaignID,
      start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
      end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date,
      is_report_data: true
    }
    setLoading(true);
    GET_CAMPAIGN_AD_GROUPS(preparedData).then((res: any) => {
      if (res?.data?.status === "error") {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          const response: any = res.data;
          const camapignData: any = localStorage.getItem('campaign_data');
          const sessionCamapignData = JSON.parse(camapignData);
          response?.data?.forEach((element: any) => {
            element.campaign_status = props?.campaignData?.state ? props?.campaignData?.state : sessionCamapignData?.state;
            element.campaign_start_date = props?.campaignData?.start_date ? props?.campaignData?.start_date : sessionCamapignData?.start_date;
          });
          for (let i = 0; i < response?.data?.length; i++) {
            const metricsData: any = response.data[i]?.metricsData || {};
            response.data[i].impressions = metricsData?.impressions ? metricsData?.impressions?.toLocaleString('en-IN') : '-';
            response.data[i].clicks = metricsData?.clicks ? metricsData?.clicks?.toLocaleString('en-IN') : '-';
            response.data[i].clickThroughRate = metricsData?.clickThroughRate ? getCTRData(parseFloat(response?.data[i]?.metricsData?.clickThroughRate)?.toFixed(2)) : '-';
            response.data[i].cost = metricsData?.cost ? (getRupeeData(metricsData?.cost)) : '-';
            response.data[i].costPerClick = metricsData?.costPerClick ? getRupeeData(metricsData?.costPerClick) : '-';
            response.data[i].purchases30d = metricsData?.purchases30d ? metricsData?.purchases30d?.toLocaleString('en-IN') : '-';
            response.data[i].sales30d = metricsData?.sales30d ? (getRupeeData(metricsData?.sales30d)) : '-';
            response.data[i].acosClicks14d = metricsData?.acosClicks14d ? (getPercentageData(metricsData?.acosClicks14d)) : '-';
            response.data[i].roasClicks14d = metricsData?.roasClicks14d ? metricsData?.roasClicks14d?.toLocaleString('en-IN') : '-';
            response.data[i].topOfSearchImpressionShare = metricsData?.topOfSearchImpressionShare ? (getPercentageData(metricsData?.topOfSearchImpressionShare)) : "-";
            response.data[i].conversion_rate = metricsData?.conversion_rate ? (getPercentageData(metricsData?.conversion_rate)) : "-";
            response.data[i].unitsSoldClicks1d = metricsData?.unitsSoldClicks1d ? metricsData?.unitsSoldClicks1d : "-";
          }
          setOriginalCampaignAdGroupsData(response?.data);
          setCampaignAdGroupsData(response?.data)
          setLoading(false);
        }
      }
    });
  }

  const getPercentageData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data == 0) {
      return "0";
    } else if (data == undefined) {
      return "-";
    } else {
      return data?.toFixed(2) + '%';
    }
  }

  const getRupeeData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data == 0) {
      return 0;
    } else if (data === undefined) {
      return "-";
    } else {
      const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
      return `${preparedData}`
    }
  }

  const getshowChatStatus = (status: any) => {
    setShowChart(status);
  }

  const onCloseTooltip = (status: any) => {
    if (status) {
      setTooltipClose(true);
    }
  }

  const handleClose = () => {
    const tooltipStatus: any = tooltipClose;
    return tooltipStatus;
  }

  const tooltipContent = (tooltipContent: any) => {
    if (tooltipContent === "Spend") {
      return (
        <TooltipPage
          tooltipHeader='Spend'
          tooltipMainContent={`The total click charges for a product ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipContent === "Sales") {
      return (
        <TooltipPage
          tooltipHeader='Sales'
          tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
              Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
              Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
              It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
              Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipContent === "CPC") {
      return (
        <TooltipPage
          tooltipHeader='Cost-per-click (CPC)'
          tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipContent === "CTR") {
      return (
        <TooltipPage
          tooltipHeader='Click-through rate (CTR)'
          tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
          tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipContent === "Impressions") {
      return (
        <TooltipPage
          tooltipHeader='Impressions'
          tooltipMainContent={`The number of times ads were displayed`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    } else if (tooltipContent === "ROAS") {
      return (
        <TooltipPage
          tooltipHeader="Return on ad spend (ROAS)"
          tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
          tooltipSubContent={`Learn about return on ad spend (ROAS)`}
          tooltipCloseStatus={onCloseTooltip} />
      )
    }
  }

  const onCreateButtonNavigation = (data: string) => {
    const navigationData = data;
    if (navigationData) {
      navigate("/new-adgroup")
    }
  }

  const isMetricSelected = (metric: any) => {
    return (
      grpahCardsData.some((selectedMetric: any) => selectedMetric.card_title === metric.card_title)
    );
  };

  const onClickAddMetric = () => {
    setCanShowAddMetric(!canShowAddMetric);
  }

  const onSelectMetric = (data: any, index: any) => {
    const preparedData = grpahCardsData;
    preparedData.push(data);
    setGrpahCardsData([...preparedData]);
    setCanShowAddMetric(false);
  }

  function onRemoveMetric(data: any, index: number) {
    const preparedData = grpahCardsData;
    preparedData.splice(index, 1);
    setGrpahCardsData([...preparedData]);
  }

  const onActions = (data: any) => {
    if (data.type === "link") {
      localStorage.setItem("ad_group_id", data?.data?.ad_group_id);
      navigate(`/dashboard/ad-groups/ads`);
    } else if (data.type === "status") {
      const toggleStatusPreparedData: any = {
        user_uuid: userUuid,
        profile_id: profileID,
        ad_group_id: data?.data?.ad_group_id,
        state: data?.data?.statusChecked ? "ENABLED" : "PAUSED",
        ad_group_name: data?.data?.ad_group_name,
        default_bid: data?.data?.default_bid,
      }
      UPDATE_AD_GROUP_STATUS(toggleStatusPreparedData).then((res) => {
        if (res.data.status === "error" || res?.data?.code == 500) {
          toast.error(res.data.message);
          setLoading(false);
        } else {
          if (res) {
            toast.success(res.data.message);
            getCampaignAdGroups(dateRangeData)
            setLoading(false);
          }
        }
      });
    } else if (data?.type === "view") {
      setSelectedAdGroupData(data?.data);
      setShowAdGroupAiActionsModal(true)
    }
  }

  const handleCustomSearch = (data: any) => {
    if (data?.trim()?.length > 0) {
      for (let i = 0; i < originalCampaignAdGroupsData?.length; i++) {
        originalCampaignAdGroupsData[i].dummy_ad_group_name = originalCampaignAdGroupsData[i].ad_group_name.toLowerCase()
      }
      const searchData = originalCampaignAdGroupsData?.filter((el: any) => el?.dummy_ad_group_name?.includes(data.toLowerCase()));
      setCampaignAdGroupsData(searchData);
    } else {
      getCampaignAdGroups(dateRangeData);
    }
  }

  const handleCustomFilter = (filterData: any) => {
    if (filterData === "enabled") {
      const searchData = originalCampaignAdGroupsData?.filter((el: any) => el?.state === "ENABLED");
      setCampaignAdGroupsData(searchData);
    } else if (filterData === "paused") {
      const searchData = originalCampaignAdGroupsData?.filter((el: any) => el?.state === "PAUSED");
      setCampaignAdGroupsData(searchData);
    } else if (filterData === "archived") {
      const searchData = originalCampaignAdGroupsData?.filter((el: any) => el?.state === "ARCHIVED");
      setCampaignAdGroupsData(searchData);
    } else if (filterData === "all") {
      getCampaignAdGroups(dateRangeData);
    } else {
      getCampaignAdGroups(dateRangeData);
    }
  }

  const handleExport = (status: any) => {
    if (status) {
      setExportLoading(true);
      setTimeout(() => {
        setExportLoading(false);
      }, 500);
    }
  }
  const handleClickSearchWrong = (status: any) => {
    if (!status) {
      getCampaignAdGroups(dateRangeData);
    }
  }

  const getPortfolioSummaryReport = (data: any) => {
    const sessionDateRange: any = localStorage?.getItem('updated_date_range')
    const dateRange = JSON.parse(sessionDateRange)
    const preparedData = {
      profile_id: profileID,
      user_uuid: userUuid,
      campaign_id: campaignID,
      start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
      end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date
    }
    GET_AD_GROUP_SUMMARY_REPORT(preparedData).then((res: any) => {
      if (res.data.status === "error" || res?.data?.code == 500) {
        setLoading(false);
      } else {
        if (res) {
          setSummaryReportsData(res.data.data[0]?.dateWiseData);
          const totalDataObj = metricCardsData;
          const grpahCardsDataobj = grpahCardsData;
          const totalData = res.data.data[0]?.totalData;
          if (totalData !== undefined) {
            Object.keys(totalData).map(function (key) {
              totalDataObj.forEach((element: any) => {
                if (element.key === key) {

                  element.amount = totalData[key]
                  if (key === 'cost' || key === 'sales' || key === 'sales') {
                    element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                  } else if (key === "impressions") {
                    element.amount = `${(totalData[key]).toLocaleString('en-IN')}`
                  } else if (key === "cpc") {
                    element.amount = `₹${totalData[key]?.toLocaleString('en-IN')?.toFixed(2)}`;
                  } else {
                    element.amount = getPercentageDataNavbrd(totalData[key]);
                  }
                }
              });
            });
            Object.keys(totalData).map(function (key) {
              grpahCardsDataobj.forEach((element: any) => {
                if (element.key === key) {
                  element.amount = totalData[key]
                  if (key === 'cost' || key === 'sales' || key === 'sales') {
                    element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                  } else if (key === "impressions") {
                    element.amount = `${(totalData[key]).toLocaleString('en-IN')}`
                  } else {
                    element.amount = getPercentageDataNavbrd(totalData[key]);
                  }
                }
              });
            });
          }
          setMetricCardsData([...totalDataObj])
          setGrpahCardsData([...grpahCardsDataobj])
        }
      }
    })
  }

  const getRupeeDataNavbrd = (data: any) => {
    if (data === "-") {
      return "-";
    } else {
      const preparedData = parseFloat(data?.toFixed(2)).toLocaleString('en-IN');
      return `${preparedData}`
    }
  }

  const getPercentageDataNavbrd = (data: any) => {
    if (data === "-") {
      return "-";
    } else {
      return data?.toFixed(2);
    }
  }
  const getCTRData = (data: any) => {
    if (data === "NaN") {
      return "-"
    } else if (data == undefined) {
      return "-";
    } else {
      return data + "%";
    }
  }

  const getDateRangeFilerData = (dateRangeData: any) => {
    getCampaignAdGroups(dateRangeData);
    getPortfolioSummaryReport(dateRangeData);
  }

  const getAdGroupSuggestion = (data: any, type: any) => {
    if (type === "AI Accept") {
      return data?.ai_suggestion?.suggestion ? data?.ai_suggestion?.suggestion : ""
    } else if (type === "Manual Accept") {
      if (data?.action_type === "increase") {
        return `${data?.action_type} budget by ${data?.action_value}%`
      } else if (data?.action_type === "reduce") {
        return `${data?.action_type} budget by ${data?.action_value}%`
      }
    }
  }

  const onAdGroupAiActionAccept = (aiAcceptData: any, acceptType: any) => {
    const preparedData = {
      user_uuid: userUuid,
      type: "ADGROUPS",
      profile_id: acceptType === "Manual Accept" ? aiAcceptData?.ad_group_data?.profile_id : aiAcceptData?.profile_id,
      campaign_id: acceptType === "Manual Accept" ? aiAcceptData?.ad_group_data?.campaign_id : aiAcceptData?.campaign_id,
      suggestion: getAdGroupSuggestion(aiAcceptData, acceptType)
    }
    // setLoading(true)
    // AI_SUGGESTION_ACCEPT(preparedData).then((res) => {
    //   if (res.data.status === "error" || res?.data?.code == 500) {
    //     toast.error(res.data.message);
    //     setLoading(false);
    //   } else {
    //     if (res) {
    //       setShowAdGroupAiActionsModal(false);
    //       setLoading(false);
    //     }
    //   }
    // });
  }

  const onAdGroupAiActionReject = () => {
    setShowAiRejectConfirmationModal(true);
  }

  const onNavigateToConfigurations = () => {
    navigate("/dashboard/configuration/groups")
  }

  const handleRejectComments = (e: any) => {
    const { name, value } = e.target;
    setAiRejectReasonData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));

    setAiRejectFormErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: validateSpecificFeild(name, value),
    }));
  }

  const validateSpecificFeild = (name: any, value: any) => {
    if (name === "rejection_message") {
      if (!value) {
        return "Please ente the comments"
      }
    }
  }

  const validateAiRejectionForm = () => {
    const errors: any = {}
    if (!aiRejectReasonData?.rejection_message) {
      errors.rejection_message = "Please enter comments"
    }

    setAiRejectFormErrors(errors);
    return Object.keys(errors).length === 0;
  }

  const onAiRejectSubmit = (data: any) => {
    setShowAiRejectConfirmationModal(false);
    setShowAdGroupAiActionsModal(false);
  }

  const onTabActions = (data: any) => {
    if (data?.type == "AI Accept") {
      onAdGroupAiActionAccept(data?.data, data?.type)
    } else if (data?.type === "Manual Accept") {
      data.data.ad_group_data = data?.campaignInfo
      onAdGroupAiActionAccept(data?.data, data?.type)
    } else if (data?.type === "navigate") {
      navigate("/dashboard/configuration/groups")
    }
  }

  const getAiRejectSubmit = (data: any) => {
    onAiRejectSubmit(data)
  }

  return (
    <div className='container-fliud'>
      <div className="row">
        <div className="col-12 mt-3 px-4">
          {showChart && (
            <div className='row px-3'>
              <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                {grpahCardsData?.map((data: any, index: number) => (
                  <div key={index} className={`card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0 ${index === selectedMetricIndex ? 'selected-"metric"-card' : ''}`} style={{ borderTop: `${index === selectedMetricIndex ? data?.border_color : ""}` }} onClick={() => selectedGraphMetric(data, index)}>
                    <div className='card-header bg-white border-0 add_metrics-bg cursor-pointer'>
                      <div className="d-flex justify-content-between">
                        <div className="fs-13">{data?.card_title}
                          <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                            <span className='ms-1 fs-15'>
                              <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                            </span>
                          </Tooltip>
                        </div>
                        <div className="fs-13 cross-icon cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                          <i className="bi cross-icon bi-x"></i>
                        </div>
                      </div>
                      <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>}&nbsp;<span className='fs-11'>{data?.sub_title}</span></div>
                    </div>
                  </div>
                ))}
                {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card  py-3 col-12 col-md-6 col-lg-2 col-xl-2 border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                  <div className='card-header text-center bg-white border-0'>
                    <span><img src={PlusIcon} /></span>
                    add metric
                    <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                    <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                  </div>
                </div>}
              </div>
              {canShowAddMetric && <div className='add-metric-list col-md-12 my-3'>
                <div className='row add-metric-info'>
                  {metricCardsData?.map((data: any, index: number) => (
                    <div key={index} className={`card col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin ${isMetricSelected(data) ? "disabled-metric cursor-pointer-not-allowed" : ""}`} onClick={() => onSelectMetric(data, index)}>
                      <div className='card-header bg-white border-0 add_metrics-bg'>
                        <div className="d-flex justify-content-between">
                          <div className="fs-13">{data?.card_title}
                            <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                              <span className='ms-1 fs-15'>
                                <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                              </span>
                            </Tooltip>
                          </div>
                          <div className="fs-13 cross-icon">
                            <i className="bi bi-x"></i>
                          </div>
                        </div>
                        <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>} <span className='fs-11'>{data?.sub_title}</span></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>}
              {!canShowAddMetric && summaryReportsData &&
                <ChartComponent chartData={summaryReportsData} />
              }
              {!summaryReportsData && <>
                <ChartComponent chartData={summaryReportsData} customCss={true} />
                <div className='d-flex align-items-center position-relative' style={{ top: "-200px" }}>
                  <div className='text-center mx-auto fs-4 fw-bold'>No ad groups are currently active</div>
                </div>
              </>
              }
            </div>
          )}
        </div>
        <div className="col-12 px-4">
          <CustomFilter showChartStatus={getshowChatStatus}
            customCreateButtonText={'Create ad group'}
            customPlaceholder='Find an ad group'
            customDateRangeText='Last 30 days'
            exportData={"ad_groups"}
            exportTableData={tableData}
            onClickButton={(e) => onCreateButtonNavigation(e)}
            handleCustomSearch={(e) => handleCustomSearch(e)}
            handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
            handleCustomFilter={(e) => handleCustomFilter(e)}
            showAdGroupDateRangeFilter={true}
            dateRangeFilterData={(e) => getDateRangeFilerData(e)}
            campaignExport={(e) => handleExport(e)}
            exportLoadingStatus={exportLoading}
            dateRangeData={dateRangeData}
          />
        </div>
        <div className='col-12 ad-groups-table-container'>
          {loading &&
            <div className="text-center p-5">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          }
          {!loading &&
            <CustomTable
              tableData={tableData}
              TableCols={AdGroupsDataGridCols}
              tablefooterData={tablefooterData}
              tableLoading={!loading}
              noDataFoundMesageTitle=''
              onActions={(e) => onActions(e)}
              pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
              tableColumnMarginLeft={496}
            />
          }
          {ShowAdGroupAiActionsModal && (
            <div className={`modal modal-custom fade ${ShowAdGroupAiActionsModal ? "show" : "false"}  ${ShowAdGroupAiActionsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
              <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content mx-auto ">
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title fs-17 fw-400">AI actions</h5>
                    <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAdGroupAiActionsModal(false)}></button>
                  </div>
                  <div className='modal-body p-3 border-bottom'>
                    <AiActionsTabs camapaignInfo={selectedAdGroupData} adGroups={true}
                      onTabActions={(e) => onTabActions(e)}
                      aiActionRejectSubmit={(e) => getAiRejectSubmit(e)} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showAiRejectConfirmationModal && (
            <div className={`modal modal-custom fade ${showAiRejectConfirmationModal ? "show" : "false"}  ${showAiRejectConfirmationModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
              <div className="modal-dialog modal modal-dialog-centered " role="document">
                <div className="modal-content mx-auto ">
                  <div className="modal-header border-bottom-0">
                    <h5 className="modal-title fs-17 fw-400">AI Reject</h5>
                    <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAiRejectConfirmationModal(false)}></button>
                  </div>
                  <div className='modal-body px-3 border-bottom'>
                    <div className='custom-explore-text-color text-start'><span className='fw-bold text-dark'>Ad group name : </span> {selectedAdGroupData?.ad_group_name}</div>
                    <div>
                      <label className="form-label d-flex jsutify-content-start">Comments</label>
                      <textarea rows={3} className="form-control" name="rejection_message" defaultValue={""} placeholder="Please enter comments" onChange={(e: any) => handleRejectComments(e)}></textarea>
                      {aiRejectFormErrors?.rejection_message && (<div className="text-danger">{aiRejectFormErrors?.rejection_message}</div>)}
                    </div>
                    <div className="d-flex justify-content-end py-3">
                      <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={onAiRejectSubmit}>Submit</button>
                      <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-5`} onClick={() => setShowAiRejectConfirmationModal(false)}>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`pb - 3 ${!showChart ? "fixed-bottom " : ""}`}>
          <CustomFooter />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    profileData: state.ProfileDataReducer,
    userData: state.UserDataReducer,
    userUuidData: state.UserUuidDataReducer,
    campaignData: state.CampaignDataReducer
  };
};

const connectedNavBar = connect(mapStateToProps)(AdGroupsListPage);
export { connectedNavBar as AdGroupsListPage };
