import React, { useEffect, useState } from 'react'
import ChartComponent from '../../../../../components/charts/line-chart'
import { TooltipPage } from '../../../../../components/custom-tooltip/tooltipPage';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import PlusIcon from "../../../../../assets/images/svg_icons/plus.svg";
import { CustomFilter } from '../../../../../components/custom-filter/custom-filter';
import { CustomTable } from '../../../../../components/custom-table';
import { AdGroupsAdsDataGridCols } from './adgroups-ads-data-grid-cols';
import { CustomFooter } from '../../../../../components/footer/custom-footer';
import { ProductsCardPage } from '../../../../../components/ad-group-creation-components/products-card';
import { toast } from "react-toastify";
import { CREATE_PRODUCTS_TO_AD, GET_AD_GROUP_PRODUCTS_ADS } from '../../../../../app/services/ad-groups-ads.service';
import { connect, useDispatch } from "react-redux";
import { AdgroupsAdsData } from '../../../../../redux/actions';
import { GET_PRODUCT_ADS_SUMMARY_REPORT } from '../../../../../app/services/portfolio-summary-report.service';
import moment from 'moment';
import { AI_SUGGESTION_ACCEPT, AI_SUGGESTION_REJECT } from '../../../../../app/services/ai-keys-update.service';
import { AiActionsTabs } from '../../../../../components/ai-actions-tabs/ai-actions-tabs';

interface Props {
    addProdcutsLength?: any;
    addedProductsData?: any;
    adProductsData?: (data: any) => void;
    UserUuidDataReducer?: any;
    userUuidData?: any;
    CampaignDataReducer?: any;
    campaignData?: any;
    AdGroupAdsDataReducer?: any;
    adgroupsAdsData?: any;
    showAdGroupAdsDateRangeFilter?: any;
}

const AdgroupAdsPage = (props: Props) => {
    const [showChart, setShowChart] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
    const [showAddProdcutsToAdveriseModal, setShowAddProdutsToAdvertiseModal] = useState<boolean>(false)
    const [addedProdcutsLength, setAddedProdcutsLength] = useState<any>();
    const [addedProdcutsData, setAddedProdcutsData] = useState<any>([]);
    const [addedProductsAsinSkuData, setAddedProductsAsinSkuData] = useState<any>([]);
    const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
    const [adgroupAdsData, setAdgroupAdsData] = useState<any>([])
    const pageData = {
        limit: '100',
        page: '1'
    };
    const navigate = useNavigate();
    const [origialAdGroupAdsData, setOrigialAdGroupAdsData] = useState<any>();
    const dispatch = useDispatch();
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const campaignID: any = localStorage.getItem("campaign_id");
    const adGroupId = localStorage.getItem("ad_group_id");
    const [summaryReportsData, setSummaryReportsData] = useState<any>();
    const [showProductAdsAiActionsModal, setShowProductAdsAiActionsModal] = useState<boolean>(false);
    const [showProductAdsAiActionRejectionModal, setShowProductAdsAiActionRejectionModal] = useState<boolean>(false);
    const [aiRejectFormErrors, setAiRejectFormErrors] = useState<any>({})
    const [selectedProductAdsData, setSelectedProductAdsData] = useState<any>([]);
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;

    const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
    const updatedData = JSON.parse(sessionUpdateDateRange);

    const [dateRangeData, setDateRangeData] = useState<any>({
        start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
    })
    const [grpahCardsData, setGrpahCardsData] = useState([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
    ]);

    const [metricCardsData, setMetricCardsData] = useState([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #ff5733",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #373782",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #fe4a4a",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #4949da",
            key: 'impressions'
        },
        {
            card_title: "CTR",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #3e1bc2",
            key: 'clickThroughRate'
        },
        {
            card_title: "Clicks",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #301934",
            key: 'clicks'
        },
        {
            card_title: "CPC",
            amount: '',
            sub_title: "AVERAGE",
            border_color: "4px solid #1d2383",
            key: 'costPerClick'
        },
        {
            card_title: "Orders",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid #53f453",
            key: 'orders'
        },
        {
            card_title: "ACOS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid #ff1cff",
            key: 'acoss'
        },
    ])

    const tableData = {
        page: "0",
        limit: "10",
        total: 14,
        rows: adgroupAdsData
    }

    const tablefooterData = [
        {
            ad_group_name: `Total: ${adgroupAdsData?.length ? adgroupAdsData?.length : 0}`,
            impressions: "-",
            clicks: "-",
            clickThroughRate: "-",
            cost: "-",
            costPerClick: "-",
            purchases30d: "-",
            sales30d: "-",
            acosClicks14d: "-",
            topOfSearchImpressionShare: "-",
            roasClicks14d: "-",
            unitsSoldClicks1d: "-",
            conversion_rate: "-"
        },
    ];

    useEffect(() => {
        const loginUserUuid = props?.campaignData?.camapain_id;
        const updatedDateRangeData: any = localStorage.getItem('updated_date_range')
        const dateRange: any = JSON.parse(updatedDateRangeData)

        const updateDateRange = {
            start_date: dateRange?.start_date ? dateRange?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
            end_date: dateRange?.end_date ? dateRange?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
        }
        setDateRangeData({ ...dateRangeData, start_date: updateDateRange?.start_date, end_date: updateDateRange?.end_date })
        if (loginUserUuid) {
            getAdGroupProductAds(dateRangeData);
        }
    }, [props?.campaignData?.camapain_id])

    useEffect(() => {
        getAdGroupProductAds(dateRangeData);
        getProductAdsSummaryReport(dateRangeData);
    }, [])

    const selectedGraphMetric = (data: any, index: number) => {
        setSelectedMetricIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const isMetricSelected = (metric: any) => {
        return (
            grpahCardsData.some((selectedMetric: any) => selectedMetric.card_title === metric.card_title)
        );
    };

    const getAdGroupProductAds = (dateRangeData: any) => {
        const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
        const updatedData = JSON.parse(sessionUpdateDateRange);

        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            campaign_id: campaignID,
            ad_group_id: adGroupId,
            start_date: updatedData?.start_date ? updatedData?.start_date : dateRangeData?.start_date,
            end_date: updatedData?.end_date ? updatedData?.end_date : dateRangeData?.end_date
        }

        setLoading(true);
        GET_AD_GROUP_PRODUCTS_ADS(preparedData).then((res) => {
            if (res?.data?.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res.data;
                    const camapignData: any = localStorage.getItem('campaign_data');
                    const sessionCamapignData = JSON.parse(camapignData);
                    response?.data?.forEach((element: any) => {
                        element.ads_title = element?.title
                        element.campaign_status = props?.campaignData?.state ? props?.campaignData?.state : sessionCamapignData?.state;
                        element.campaign_start_date = props?.campaignData?.start_date ? props?.campaignData?.start_date : sessionCamapignData?.start_date;
                    });
                    const data = response?.data;
                    localStorage.setItem('ads_group_data', data)
                    if (props?.adgroupsAdsData) {
                        dispatch(props?.adgroupsAdsData(response?.data));
                    }
                    for (let i = 0; i < response.data.length; i++) {
                        response.data[i].impressions = response.data[i]?.metricsData ? response?.data[i]?.metricsData?.impressions?.toLocaleString('en-IN') : '-';
                        response.data[i].clicks = response.data[i]?.metricsData ? response?.data[i]?.metricsData?.clicks?.toLocaleString('en-IN') : '-';
                        response.data[i].clickThroughRate = response.data[i]?.metricsData ? getCTRData(parseFloat(response?.data[i]?.metricsData?.clickThroughRate)?.toFixed(2)) : '-'
                        response.data[i].cost = response.data[i]?.metricsData ? (getRupeeData(response?.data[i]?.metricsData?.cost)) : '-';
                        response.data[i].costPerClick = response.data[i]?.metricsData ? getRupeeData(response.data[i]?.metricsData?.costPerClick) : '-';
                        response.data[i].purchases30d = response.data[i]?.metricsData ? response.data[i]?.metricsData?.purchases30d === undefined ? "-" : response.data[i]?.metricsData?.purchases30d?.toLocaleString('en-IN') : '-';
                        response.data[i].sales30d = response.data[i]?.metricsData ? (getRupeeData(response.data[i]?.metricsData?.sales30d)) : '-';
                        response.data[i].acosClicks14d = response.data[i]?.metricsData ? (getPercentageData(response?.data[i]?.metricsData?.acosClicks14d)) : '-';
                        response.data[i].roasClicks14d = isNaN(parseFloat(response.data[i]?.metricsData?.roasClicks14d))
                            ? "-"
                            : parseFloat(response.data[i]?.metricsData?.roasClicks14d)?.toFixed(2);
                        response.data[i].topOfSearchImpressionShare = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.topOfSearchImpressionShare) : "-";
                        response.data[i].conversion_rate = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.conversion_rate) : "-";
                        response.data[i].unitsSoldClicks1d = response.data[i]?.metricsData?.unitsSoldClicks1d !== undefined
                            ? response.data[i]?.metricsData?.unitsSoldClicks1d
                            : "-";
                        response.data[i].benchmark = response.data[i].benchmark ? response.data[i].benchmark : "-";
                        response.data[i].qualifier = response.data[i].qualifier ? response.data[i].qualifier : "-";
                    }
                    setOrigialAdGroupAdsData(response?.data)
                    setAdgroupAdsData(response?.data)
                    if (props?.adProductsData) {
                        props?.adProductsData(response?.data)
                    }
                    setLoading(false);
                }
            }
        });
    }
    const getCTRData = (data: any) => {
        if (data === "NaN") {
            return "-"
        } else if (data == undefined) {
            return "-";
        } else {
            return data + "%";
        }
    }

    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data == 0) {
            return "0";
        } else if (data == undefined) {
            return "-";
        } else {
            return data?.toFixed(2) + '%';
        }
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data == 0) {
            return 0;
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }
    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader='Spend'
                    tooltipMainContent={`The total click charges for a product ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader='Sales'
                    tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
                  Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
                  Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                  It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                  Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CPC") {
            return (
                <TooltipPage
                    tooltipHeader='Cost-per-click (CPC)'
                    tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CTR") {
            return (
                <TooltipPage
                    tooltipHeader='Click-through rate (CTR)'
                    tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader='Impressions'
                    tooltipMainContent={`The number of times ads were displayed`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ROAS") {
            return (
                <TooltipPage
                    tooltipHeader="Return on ad spend (ROAS)"
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Ad Group ACOS") {
            return (
                <TooltipPage
                    tooltipHeader="Advertising cost of sales (ACOS)"
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const onClickAddMetric = () => {
        setCanShowAddMetric(!canShowAddMetric);
    }

    const onSelectMetric = (data: any, index: any) => {
        const preparedData = grpahCardsData;
        preparedData.push(data);
        setGrpahCardsData([...preparedData]);
        setCanShowAddMetric(false);
    }

    function onRemoveMetric(data: any, index: number) {
        const preparedData = grpahCardsData;
        preparedData.splice(index, 1);
        setGrpahCardsData([...preparedData]);
    }

    const getshowChatStatus = (status: any) => {
        setShowChart(status);
    }

    const onCreateButtonNavigation = (data: string) => {
        const navigationData = data;
        if (navigationData) {
            setShowAddProdutsToAdvertiseModal(true)
        }
    }

    const onActions = (data: any) => {
        console.log('aaaaaaaaa', data);
        
        if (data?.type === "prodcut_navigation" || data?.type === "link") {
            const link = `https://www.amazon.in/dp/${data?.data?.asin}`;
            window.open(link, "_blank")
        } else if (data?.type === "view") {
            setSelectedProductAdsData(data?.data);
            setShowProductAdsAiActionsModal(true);
        } else if (data?.type === "AI Accept") {
            setSelectedProductAdsData({...data?.data})
            onAiActionAccept(data?.data, data?.type);
        } else if (data?.type === "AI Reject") {
            setSelectedProductAdsData(data?.data)
            setShowProductAdsAiActionsModal(true);
        }
    }
    const getProductsAsinSkuData = (data: any) => {
        setAddedProductsAsinSkuData(data);
    }
    const onClickAddProductstoAdvertise = () => {
        if (addedProdcutsData?.length > 0) {
            const preparedData = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: campaignID,
                ad_group_id: adGroupId,
                product_ads: addedProductsAsinSkuData
            };

            setLoading(true);
            CREATE_PRODUCTS_TO_AD(preparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code == 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.data.message);
                        setShowAddProdutsToAdvertiseModal(false);
                        const dateRangeData = {
                            start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                            end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                        }
                        getAdGroupProductAds(dateRangeData);
                        setLoading(false);
                    }
                }
            });
        } else {
            toast.error("Please select at least one product");
        }
    };

    const addedProdcutsDataLength = (data: any) => {
        setAddedProdcutsLength(data)
    }

    const getAddedProductData = (data: any) => {
        setAddedProdcutsData(data)
    }

    const handleCustomSearch = (data: any) => {
        if (data?.trim()?.length > 0) {
            for (let i = 0; i < origialAdGroupAdsData?.length; i++) {
                origialAdGroupAdsData[i].dummy_title = origialAdGroupAdsData[i].title.toLowerCase();
            }
            const searchData = origialAdGroupAdsData?.filter((el: any) => el?.dummy_title?.includes(data.toLowerCase()));
            setAdgroupAdsData(searchData);
        } else {
            const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
            const updatedData = JSON.parse(sessionUpdateDateRange);

            const dateRangeData = {
                start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
                end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
            }
            getAdGroupProductAds(dateRangeData);
        }
    }

    const handleClickSearchWrong = (status: any) => {
        if (!status) {
            const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
            const updatedData = JSON.parse(sessionUpdateDateRange);

            const dateRangeData = {
                start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
                end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
            }
            getAdGroupProductAds(dateRangeData);
        }
    }

    const handleCustomFilter = (filterData: any) => {
        const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
        const updatedData = JSON.parse(sessionUpdateDateRange);

        const dateRangeData = {
            start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
            end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
        }
        if (filterData === "enabled") {
            const searchData = origialAdGroupAdsData?.filter((el: any) => el?.state === "ENABLED");
            setAdgroupAdsData(searchData);
        } else if (filterData === "paused") {
            const searchData = origialAdGroupAdsData?.filter((el: any) => el?.state === "PAUSED");
            setAdgroupAdsData(searchData);
        } else if (filterData === "archived") {
            const searchData = origialAdGroupAdsData?.filter((el: any) => el?.state === "ARCHIVED");
            setAdgroupAdsData(searchData);
        } else if (filterData === "all") {
            getAdGroupProductAds(dateRangeData);
        } else {
            getAdGroupProductAds(dateRangeData);
        }
    }

    const handleExport = (status: any) => {
        if (status) {
            setExportLoading(true);
            setTimeout(() => {
                setExportLoading(false);
            }, 500);
        }
    }

    const getProductAdsSummaryReport = (data: any) => {
        const sessionDateRange: any = localStorage?.getItem('updated_date_range')
        const dateRange = JSON.parse(sessionDateRange)
        const preparedData = {
            profile_id: profileID,
            user_uuid: userUuid,
            campaign_id: campaignID,
            adgroup_id: adGroupId,
            start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
            end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date
        }
        GET_PRODUCT_ADS_SUMMARY_REPORT(preparedData).then((res: any) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                setLoading(false);
            } else {
                if (res) {
                    setSummaryReportsData(res.data.data[0]?.dateWiseData);
                    const totalDataObj = metricCardsData;
                    const grpahCardsDataobj = grpahCardsData;

                    const totalData = res.data.data[0]?.totalData;
                    if (totalData !== undefined) {
                        Object.keys(totalData).map(function (key) {
                            totalDataObj.forEach((element: any) => {
                                if (element.key === key) {
                                    element.amount = totalData[key]
                                    if (key === 'cost' || key === 'sales' || key === 'sales') {
                                        element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                                    } else if (key === "impressions") {
                                        element.amount = `${(totalData[key])?.toLocaleString('en-IN')}`
                                    } else {
                                        element.amount = getPercentageDataNavbrd(totalData[key]);
                                    }
                                }
                            });
                        });
                        Object.keys(totalData).map(function (key) {
                            grpahCardsDataobj.forEach((element: any) => {
                                if (element.key === key) {
                                    element.amount = totalData[key]
                                    if (key === 'cost' || key === 'sales' || key === 'sales') {
                                        element.amount = `₹${getRupeeDataNavbrd(totalData[key])}`;
                                    } else if (key === "impressions") {
                                        element.amount = `${(totalData[key])?.toLocaleString('en-IN')}`
                                    } else {
                                        element.amount = getPercentageDataNavbrd(totalData[key]);
                                    }
                                }
                            });
                        });
                    }
                    setMetricCardsData([...totalDataObj])
                    setGrpahCardsData([...grpahCardsDataobj])
                }
            }
        })
    }

    const getRupeeDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2)).toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const getPercentageDataNavbrd = (data: any) => {
        if (data === "-") {
            return "-";
        } else {
            return data?.toFixed(2);
        }
    }

    const getAdProductsDateRangeData = (data: any) => {
        getAdGroupProductAds(data);
        getProductAdsSummaryReport(data);
    }

    const getProductAdSuggestion = (data: any, type: any) => {
        if (type === "AI Accept") {
            return data?.ai_suggestion?.suggestion ? data?.ai_suggestion?.suggestion : ""
        } else if (type === "Manual Accept") {
            if (data?.action_type === "increase") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            } else if (data?.action_type === "reduce") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            } else if (data?.action_type === "pause") {
                return "pause"
            } else if (data?.action_type === "continue") {
                return "continue"
            }
        }
    }

    const onAiActionAccept = (aiAcceptData: any, acceptType: any) => {
        console.log('aiAcceptData', aiAcceptData);
        
        const preparedData = {
            user_uuid: userUuid,
            adId: aiAcceptData?.ad_id || aiAcceptData?.adId,
            type: "PRODUCTADS",
            profile_id: acceptType === "Manual Type" ? aiAcceptData?.product_ad_data?.profile_id : profileID,
            campaign_id: acceptType === "Manual Type" ? aiAcceptData?.product_ad_data?.campaign_id : aiAcceptData?.campaign_id,
            suggestion: getProductAdSuggestion(aiAcceptData, acceptType),
            uuid: aiAcceptData?.ai_suggestion?.uuid
        }        
        setLoading(true)
        AI_SUGGESTION_ACCEPT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    toast.success(res.data.message);
                    setShowProductAdsAiActionsModal(false);
                    const sessionUpdateDateRange: any = localStorage?.getItem('updated_date_range');
                    const updatedData = JSON.parse(sessionUpdateDateRange);

                    const dateRangeData = {
                        start_date: updatedData?.start_date ? updatedData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
                        end_date: updatedData?.end_date ? updatedData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
                    }
                    getAdGroupProductAds(dateRangeData);
                    getProductAdsSummaryReport(dateRangeData);
                    setLoading(false);
                }
            }
        });
    }

    const handleRejectComments = (e: any) => {
        const { name, value } = e.target;
        setAiRejectFormErrors((prevErrors: any) => ({
            ...prevErrors,
            [name]: validateSpecificFeild(name, value),
        }));
    }

    const validateSpecificFeild = (name: any, value: any) => {
        if (name === "rejection_message") {
            if (!value) {
                return "Please enter the comments"
            }
        }
    }

    const onAiActionRejectSubmit = (data: any) => {
        const preparedData = {
            user_uuid: userUuid,
            uuid: data?.uuid ? data?.uuid : "",
            rejection_message: data?.rejection_message
        }
        setLoading(true)
        AI_SUGGESTION_REJECT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code == 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    setShowProductAdsAiActionRejectionModal(false);
                    setShowProductAdsAiActionsModal(false);
                    const dateRangeData = {
                        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                    }
                    getAdGroupProductAds(dateRangeData);
                    getProductAdsSummaryReport(dateRangeData);
                    setLoading(false);
                }
            }
        })
    }

    const onTabActions = (data?: any) => {
        if (data?.type == "AI Accept") {
            onAiActionAccept(data?.data, data?.type)
        } else if (data?.type === "Manual Accept") {
            data.data.product_ad_data = data?.campaignInfo;
            onAiActionAccept(data?.data, data?.type)
        } else if (data?.type === "navigate") {
            navigate("/dashboard/configuration/groups-info/products")
        }
    }

    const getAiRejectSubmit = (data: any) => {
        onAiActionRejectSubmit(data);
    }

    const getAiRejectCancel = (data: any) => {
        setShowProductAdsAiActionsModal(false);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-3 px-4">
                    {showChart && (
                        <div className='row px-3'>
                            <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                                {grpahCardsData?.map((data: any, index: number) => (
                                    <div key={index} className={`card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0 ${index === selectedMetricIndex ? 'selected-"metric"-card' : ''}`} style={{ borderTop: `${index === selectedMetricIndex ? data?.border_color : ""}` }} onClick={() => selectedGraphMetric(data, index)}>
                                        <div className='card-header bg-white border-0 add_metrics-bg cursor-pointer'>
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-13">{data?.card_title}
                                                    <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                        <span className='ms-1 fs-15'>
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </div>
                                            <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>}<span className='fs-11'>{data?.sub_title}</span></div>
                                        </div>
                                    </div>
                                ))}
                                {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card  py-3 col-12 col-md-6 col-lg-2 col-xl-2 border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                                    <div className='card-header text-center bg-white border-0'>
                                        <span><img src={PlusIcon} /></span>
                                        add metric
                                        <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                                        <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                                    </div>
                                </div>}
                            </div>
                            {canShowAddMetric && <div className='add-metric-list col-md-12 my-3'>
                                <div className='row add-metric-info '>
                                    {metricCardsData?.map((data: any, index: number) => (
                                        <div key={index} className={`card col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin ${isMetricSelected(data) ? "disabled-metric cursor-pointer-not-allowed" : ""}`} onClick={() => onSelectMetric(data, index)}>
                                            <div className='card-header bg-white border-0 add_metrics-bg'>
                                                <div className="d-flex justify-content-between">
                                                    <div className="fs-13">{data?.card_title}
                                                        <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                            <span className='ms-1 fs-15'>
                                                                <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="fs-13">
                                                        <i className="bi bi-x"></i>
                                                    </div>
                                                </div>
                                                <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>} <span className='fs-11'>{data?.sub_title}</span></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>}
                            {!canShowAddMetric && summaryReportsData &&
                                <ChartComponent chartData={summaryReportsData} />
                            }
                            {!summaryReportsData &&
                                <>
                                    <ChartComponent chartData={summaryReportsData} customCss={true} />
                                    <div className='d-flex align-items-center position-relative' style={{ top: "-200px" }}>
                                        <div className='text-center mx-auto fs-4 fw-bold'>No ads are currently active</div>
                                    </div>
                                </>
                            }
                        </div>
                    )}
                </div>
                <div className="col-12 px-4">
                    <CustomFilter showChartStatus={getshowChatStatus}
                        customCreateButtonText={'Add products to advertise'}
                        customPlaceholder='Find a product'
                        customDateRangeText='Yesterday'
                        exportData={"Product Ads"}
                        exportTableData={tableData}
                        onClickButton={(e) => onCreateButtonNavigation(e)}
                        handleCustomSearch={(e) => handleCustomSearch(e)}
                        handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                        handleCustomFilter={(e) => handleCustomFilter(e)}
                        showAdGroupAdsDateRangeFilter={true}
                        campaignExport={(e) => handleExport(e)}
                        exportLoadingStatus={exportLoading}
                        dateRangeFilterData={(e) => getAdProductsDateRangeData(e)}
                        dateRangeData={dateRangeData}
                    />
                </div>
                <div className='col-12 ad-groups-table-container'>
                    {loading &&
                        <div className="text-center p-5">
                            <div className="spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    }
                    {!loading &&
                        <CustomTable
                            tableData={tableData}
                            TableCols={AdGroupsAdsDataGridCols}
                            tablefooterData={tablefooterData}
                            tableLoading={!loading}
                            noDataFoundMesageTitle=''
                            onActions={(e) => onActions(e)}
                            pageData={pageData}
                            pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                        />
                    }
                    {showProductAdsAiActionsModal && (
                        <div className={`modal modal-custom fade ${showProductAdsAiActionsModal ? "show" : "false"}  ${showProductAdsAiActionsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                                <div className="modal-content mx-auto ">
                                    <div className="modal-header border-bottom-0">
                                        <h5 className="modal-title fs-17 fw-400">AI actions</h5>
                                        <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowProductAdsAiActionsModal(false)}></button>
                                    </div>
                                    {loading &&
                                        <div className="text-center p-5">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                    {!loading &&
                                        <div className='modal-body p-3 border-bottom'>
                                            <AiActionsTabs camapaignInfo={selectedProductAdsData} productAds={true}
                                                onTabActions={(e) => onTabActions(e)} isProductAdsActions={true}
                                                aiActionRejectSubmit={(e) => getAiRejectSubmit(e)}
                                                aiActionRejectCancel={(e) => getAiRejectCancel(e)}
                                                isShowReject={true}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                    {showProductAdsAiActionRejectionModal && (
                        <div className={`modal modal-custom fade ${showProductAdsAiActionRejectionModal ? "show" : "false"}  ${showProductAdsAiActionRejectionModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                            <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                                <div className="modal-content mx-auto ">
                                    <div className="modal-header border-bottom-0">
                                        <h5 className="modal-title fs-17 fw-400">AI Reject</h5>
                                        <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowProductAdsAiActionRejectionModal(false)}></button>
                                    </div>
                                    <div className='modal-body p-3 border-bottom'>
                                        <div className='custom-explore-text-color text-start'><span className='fw-bold text-dark'>Product ad name :</span> {selectedProductAdsData?.title}</div>
                                        <div>
                                            <label className="form-label d-flex jsutify-content-start">Comments</label>
                                            <textarea rows={3} className="form-control" name="rejection_message" defaultValue={""} placeholder="Please enter comments" onChange={(e: any) => handleRejectComments(e)}></textarea>
                                            {aiRejectFormErrors?.rejection_message && (<div className="text-danger">{aiRejectFormErrors?.rejection_message}</div>)}
                                        </div>

                                        <div className="d-flex justify-content-end py-3">
                                            <button type="button" className="request-call-button text-white rounded-pill border-0 fs-13 cursor-pointer px-5 mx-3" onClick={onAiActionRejectSubmit}>Submit</button>
                                            <button type="button" className={`btn btn-secondary custom-button-background rounded-pill text-dark border-0 px-5`} onClick={() => setShowProductAdsAiActionRejectionModal(false)}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`pb-3 ${!showChart ? "fixed-bottom " : ""}`}>
                    <CustomFooter />
                </div>
            </div>
            {showAddProdcutsToAdveriseModal && (
                <div className={`modal modal-custom fade ${showAddProdcutsToAdveriseModal ? "show" : "false"}  ${showAddProdcutsToAdveriseModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered mx-auto mt-4 pt-4 ad-groups-targeting-modal" role="document">
                        <div className="modal-content mx-auto">
                            <div className="modal-header">
                                <h5 className="modal-title portfolio-title fs-17">Add products to advertise</h5>
                                <button type="button" className="btn-close fs-12" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowAddProdutsToAdvertiseModal(false)}></button>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading &&
                                <div className='modal-body negative-keyword-body negative-keyword-body-ads_modal py-0'>
                                    <ProductsCardPage productsAddToAvertise={true}
                                        addedProductsAsinSkuData={(e) => getProductsAsinSkuData(e)}
                                        addedProdcutsLength={(e) => addedProdcutsDataLength(e)}
                                        addedProductsData={getAddedProductData} />
                                </div>
                            }
                            <div className="modal-footer">
                                <button type="button"
                                    className="custom-explore-text-color border-0 bg-transparent logout fs-13"
                                    onClick={() => setShowAddProdutsToAdvertiseModal(false)}
                                >
                                    Cancel
                                </button>
                                <button type="button"
                                    className={`rounded-pill border-0 fs-13 ${addedProdcutsLength > 0 ? "request-call-button text-white cursor-pointer" : "custom-cancel-btn create-portfolio-btn custom-button-background cursor-pointer"}`}
                                    onClick={onClickAddProductstoAdvertise}>
                                    Add products to advertise
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        profileData: state.ProfileDataReducer,
        userData: state.UserDataReducer,
        campaignData: state.CampaignDataReducer
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        adgroupsAdsData: (data: any) => dispatch(AdgroupsAdsData(data)),
    };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AdgroupAdsPage);
export { connectedNavBar as AdgroupAdsPage };
