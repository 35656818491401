import React, { useEffect, useState } from 'react'
import { GET_USER_PORTFOLIOS } from '../../../app/services/portfolios.service';
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { CustomFilter } from '../../../components/custom-filter/custom-filter';
import { CustomTable } from '../../../components/custom-table';
import { Tooltip } from '@mui/material';
import { CustomFooter } from '../../../components/footer/custom-footer';
import { TooltipPage } from '../../../components/custom-tooltip/tooltipPage';
import PlusIcon from "./../../../assets/images/svg_icons/plus.svg";
import { PortfolioInfoCampaignsDataGridCols } from './portfolio-info-campaigns-data-grid-cols';
import ChartComponent from '../../../components/charts/line-chart';
import { GET_USER_CAMPAIGNS, UPDATE_CAMPAIGN_STATUS } from '../../../app/services/campaigns.service';
import moment from 'moment';
import { AiActionsTabs } from '../../../components/ai-actions-tabs/ai-actions-tabs';
import { AI_SUGGESTION_ACCEPT, AI_SUGGESTION_REJECT } from '../../../app/services/ai-keys-update.service';

interface Props {
    dateRangeData?: any;
}
export const PortfolioInfoPage = (props: Props) => {
    const [pageData, setPageData] = useState({
        limit: '100',
        page: '1'
    });
    const [showChart, setShowChart] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const userUuid = localStorage.getItem('user_uuid');
    const profileID = localStorage.getItem('profile_id');
    const [portfoliosData, setPortfoliosData] = useState<any>();
    const [searchTermData, setSearchTermData] = useState<any>("");
    const [portfolioInfoCampaignsData, setPortfolioInfoCampaignsData] = useState<any>([]);
    const [canShowAddMetric, setCanShowAddMetric] = useState<boolean>(false);
    const [summaryReportsData, setSummaryReportsData] = useState<any>();
    const [originalPortfolioInfoCampaignsData, setOriginalPortfolioInfoCampaignsData] = useState<any>([])
    let { portfolio_id } = useParams<{ portfolio_id: any }>();
    const portfolioId: any = portfolio_id;
    const [tooltipClose, setTooltipClose] = useState<boolean>(false);
    const [selectedMetricIndex, setSelectedMetricIndex] = useState<number | null>(null);
    const navigate = useNavigate();
    const [isAiActionsModal, setIsAiActionsModal] = useState<boolean>(false);
    const [selectedCampaignData, setSelectedCampaignData] = useState<any>([]);
    const currentDate = new Date();
    const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
    const defaultEndDate = currentDate;
    const updateDateRange: any = localStorage?.getItem('updated_date_range');
    const updateData = JSON.parse(updateDateRange);

    const [dateRangeData, setDateRangeData] = useState<any>({
        start_date: updateData?.start_date ? updateData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: updateData?.end_date ? updateData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
    });

    const [grpahCardsData, setGrpahCardsData] = useState([
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(46, 140, 184)",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(221, 119, 3)",
            key: 'cost'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid rgb(88, 112, 33)",
            key: 'roas'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(163, 49, 123)",
            key: 'impressions'
        },
    ]);

    const metricCardsData = [
        {
            card_title: "Sales",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(46, 140, 184)",
            key: 'sales'
        },
        {
            card_title: "Spend",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(221, 119, 3)",
            key: 'cost'
        },
        {
            card_title: "CTR",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid rgb(221, 119, 3)",
            key: 'clickThroughRate'
        },
        {
            card_title: "ROAS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid rgb(88, 112, 33)",
            key: 'roas'
        },
        {
            card_title: "Orders",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(163, 49, 123)",
            key: 'orders'
        },
        {
            card_title: "Impressions",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(163, 49, 123)",
            key: 'impressions'
        },
        {
            card_title: "CPC",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid rgb(88, 112, 33)",
            key: 'costPerClick'
        },
        {
            card_title: "Clicks",
            amount: ``,
            sub_title: "TOTAL",
            border_color: "4px solid rgb(221, 119, 3)",
            key: 'clicks'
        },
        {
            card_title: "ACOS",
            amount: ``,
            sub_title: "AVERAGE",
            border_color: "4px solid rgb(221, 119, 3)",
            key: 'acoss'
        },
    ];

    const tablefooterData = [
        {
            campaign_name: `Total: ${portfolioInfoCampaignsData?.data?.length ? portfolioInfoCampaignsData?.data?.length : 0}`,
        },
    ];

    useEffect(() => {
        const updatedDateRangeData: any = localStorage.getItem('updated_date_range')
        const dateRange: any = JSON.parse(updatedDateRangeData)
        if (dateRange) {
            const data = dateRangeData;
            data.start_date = dateRange?.start_date
            data.end_date = dateRange?.end_date
            setDateRangeData({ ...dateRangeData, start_date: dateRange?.start_date, end_date: dateRange?.end_date })
        }
    }, [])


    useEffect(() => {
        getUserPortfolioByID();
        getPortfolioCamapaignsData(dateRangeData, searchTermData);
    }, [])

    const getshowChatStatus = (status: any) => {
        setShowChart(status);
    }

    const getPortfolioCamapaignsData = (data: any, searchData: any) => {
        const sessionDateRange: any = localStorage?.getItem('updated_date_range');
        const dateRange = JSON.parse(sessionDateRange);
        const preparedData = {
            user_uuid: userUuid,
            profile_id: profileID,
            portfolio_id: portfolioId,
            start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
            end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date,
            is_report_data: true,
            campaign_name: searchData ? searchData : ""
        }
        setLoading(true);
        GET_USER_CAMPAIGNS({ ...preparedData, ...pageData }).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.data;
                    for (let i = 0; i < response?.data?.length; i++) {
                        response.data[i].impressions = response.data[i]?.metricsData ? response.data[i]?.metricsData?.impressions?.toLocaleString('en-IN') : '-';
                        response.data[i].clicks = response.data[i]?.metricsData ? response.data[i]?.metricsData?.clicks?.toLocaleString('en-IN') : '-';
                        response.data[i].clickThroughRate = response?.data[i]?.metricsData ? getCTRData(parseFloat(response?.data[i]?.metricsData?.clickThroughRate).toFixed(2)) : '-';
                        response.data[i].cost = response.data[i]?.metricsData ? (getRupeeData(response.data[i]?.metricsData?.cost)) : '-';
                        response.data[i].costPerClick = response.data[i]?.metricsData ? getRupeeData(response.data[i]?.metricsData.costPerClick) : '-';
                        response.data[i].purchases30d = response.data[i]?.metricsData ? response.data[i]?.metricsData?.purchases30d === undefined ? "-" : response.data[i]?.metricsData?.purchases30d?.toLocaleString('en-IN') : '-';
                        response.data[i].sales30d = response.data[i]?.metricsData ? (getRupeeData(response.data[i]?.metricsData?.sales30d)) : '-';//purchasesSameSku14d
                        response.data[i].acosClicks14d = response.data[i]?.metricsData ? (getPercentageData(response.data[i]?.metricsData?.acosClicks14d)) : '-';
                        response.data[i].roasClicks14d = isNaN(parseFloat(response.data[i]?.metricsData?.roasClicks14d)) ? "-" : parseFloat(response.data[i]?.metricsData?.roasClicks14d)?.toFixed(2);
                        response.data[i].topOfSearchImpressionShare = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.topOfSearchImpressionShare) : "-";
                        response.data[i].conversion_rate = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.conversion_rate) : "-";
                        response.data[i].unitsSoldClicks1d = response.data[i]?.metricsData ? response.data[i]?.metricsData?.unitsSoldClicks1d : "-";
                        response.data[i].benchmark = response.data[i].benchmark ? response.data[i].benchmark : "-";
                        response.data[i].qualifier = response.data[i].qualifier ? response.data[i].qualifier : "-";
                    }
                    setPortfolioInfoCampaignsData(response)
                    setOriginalPortfolioInfoCampaignsData(response.data);
                    setLoading(false);
                }
            }
        });
    }

    const getCTRData = (data: any) => {
        if (data === "NaN") {
            return "-"
        } else if (data === undefined) {
            return "-";
        } else {
            return data + "%";
        }
    }

    const getPercentageData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return "0";
        } else if (data === undefined) {
            return "-";
        } else {
            return data?.toFixed(2) + '%';
        }
    }

    const getRupeeData = (data: any) => {
        if (data === "-") {
            return "-";
        } else if (data === 0) {
            return 0;
        } else if (data === undefined) {
            return "-";
        } else {
            const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
            return `${preparedData}`
        }
    }

    const onPageChange = (newPage: any) => {
        const page = pageData;
        page.page = (newPage + 1).toString()
        setPageData({ ...page });
        getPortfolioCamapaignsData(dateRangeData, searchTermData);
    }

    const rowsPerChangeData = (data: any) => {
        const page = pageData;
        page.page = '1'
        page.limit = data.toString();
        setPageData({ ...page });
        getPortfolioCamapaignsData(dateRangeData, searchTermData);
    }

    const getUserPortfolioByID = () => {
        const preparedData: any = {
            user_uuid: userUuid,
            profile_id: profileID,
            portfolio_id: portfolioId,
            is_report_data: false
        }
        GET_USER_PORTFOLIOS(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
            } else {
                if (res) {
                    const response = res.data;
                    setPortfoliosData(response?.data[0])
                }
            }
        });
    }

    const onClickModifyPortofolio = () => {
        localStorage.setItem('portfolio_settings_id', portfolioId)
        navigate(`/dashboard/portfolio/settings/${portfolioId}`);
    }

    const onCreateButtonNavigation = (data: any) => {
        if (data) {
            if (portfolioId) {
                navigate(`/dashboard/campaign-type/${portfolioId}`);
            }
        }
    }
    const onCreateDotsButtonNavigation = (data: any) => {

    }

    const onActions = (data: any) => {
        if (data.type === "link") {
            localStorage.setItem("campaign_id", data.data.campaign_id)
            navigate(`/dashboard/campaign/ad-groups`)
        } else if (data.type === "status") {
            const status: any = data?.data?.statusChecked ? "ENABLED" : "PAUSED";
            const toggleStatusPreparedData: any = {
                user_uuid: userUuid,
                profile_id: profileID,
                campaign_id: data.data.campaign_id,
                state: status
            }
            UPDATE_CAMPAIGN_STATUS(toggleStatusPreparedData).then((res) => {
                if (res.data.status === "error" || res?.data?.code === 500) {
                    toast.error(res.data.message);
                    setLoading(false);
                } else {
                    if (res) {
                        toast.success(res.data.message);
                        getPortfolioCamapaignsData(dateRangeData, searchTermData)
                        setLoading(false);
                    }
                }
            });
        } else if (data?.type === "view") {
            setSelectedCampaignData(data?.data);
            setIsAiActionsModal(true);
        }
    }

    const onTabActions = (data: any) => {
        if (data?.type === "AI Accept") {
            onAISugesstionAccept(data?.data, data?.type);
        } else if (data?.type === "Manual Accept") {
            data.data.campaign_data = data?.campaignInfo
            onAISugesstionAccept(data.data, data?.type)
        } else if (data?.type === "navigate") {
            navigate("/dashboard/configuration")
        }
    }

    const getAiRejectSubmit = (aiRejectData: any) => {
        handleAiRejectSubmit(aiRejectData)
    }

    const handleAiRejectSubmit = (data: any) => {
        const preparedData = {
            user_uuid: userUuid,
            uuid: data?.uuid ? data?.uuid : "",
            rejection_message: data?.rejection_message
        }
        setLoading(true)
        AI_SUGGESTION_REJECT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    const dateRangeData = {
                        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                    }
                    getPortfolioCamapaignsData(dateRangeData, searchTermData)
                    setIsAiActionsModal(false);
                    setLoading(false);
                }
            }
        });
    }
    const getSuggestion = (data: any, type: any) => {
        if (type === "AI Accept") {
            return data?.ai_suggestion?.suggestion ? data?.ai_suggestion?.suggestion : ""
        } else if (type === "Manual Accept") {
            if (data?.action_type === "increase") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            } else if (data?.action_type === "reduce") {
                return `${data?.action_type} budget by ${data?.action_value}%`
            }
        }
    }


    const onAISugesstionAccept = (aiAcceptData: any, acceptType: any) => {
        const preparedData = {
            user_uuid: userUuid,
            type: "CAMPAIGNS",
            profile_id: profileID,
            campaign_id: acceptType === "Manual Accept" ? aiAcceptData?.campaign_data?.campaign_id : aiAcceptData?.campaign_id,
            suggestion: getSuggestion(aiAcceptData, acceptType),
            uuid: aiAcceptData?.ai_suggestion?.uuid
        }
        setLoading(true)
        AI_SUGGESTION_ACCEPT(preparedData).then((res) => {
            if (res.data.status === "error" || res?.data?.code === 500) {
                toast.error(res.data.message);
                setLoading(false);
            } else {
                if (res) {
                    toast.success(res.data.message);
                    setIsAiActionsModal(false);
                    toast.success(res.data.message);
                    const dateRangeData = {
                        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
                    }
                    getPortfolioCamapaignsData(dateRangeData, searchTermData)
                    setLoading(false);
                }
            }
        });
    }

    const tooltipContent = (tooltipContent: any) => {
        if (tooltipContent === "Spend") {
            return (
                <TooltipPage
                    tooltipHeader='Spend'
                    tooltipMainContent={`The total click charges for a product ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Sales") {
            return (
                <TooltipPage
                    tooltipHeader='Sales'
                    tooltipMainContent={` Sales is the total value of products sold to shoppers within the specified time frame for a type of campaign due to clicks or views of your ads.
                  Sponsored Products: Sales from advertised products as well as other products from your inventory purchased within 14 days.
                  Sponsored Brands: Sales from advertised products sold by Amazon within 14 days. Where eligible, this also includes sales from all products within your brand sold by Amazon and third-party sellers.
                  It can take up to 12 hours for your sales data to update. As a result, sales data may be delayed in the Today date range. We recommend waiting until all sales data is populated before evaluating campaign performance.
                  Payment failures and orders that are cancelled within 72 hours will be removed from sales totals.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CPC") {
            return (
                <TooltipPage
                    tooltipHeader='Cost-per-click (CPC)'
                    tooltipMainContent={`This is the average amount you paid for each click on an ad.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "CTR") {
            return (
                <TooltipPage
                    tooltipHeader='Click-through rate (CTR)'
                    tooltipMainContent={`The ratio of how often shoppers click on your ad when displayed. This is calculated as clicks divided by impressions.`}
                    tooltipWomensItalicText={`Note: Once identified, it may take up to 3 days to remove invalid clicks from your spend statistics. Date ranges that include spend from the last 3 days may be adjusted due to click and spend invalidation.`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "Impressions") {
            return (
                <TooltipPage
                    tooltipHeader='Impressions'
                    tooltipMainContent={`The number of times ads were displayed`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        } else if (tooltipContent === "ROAS") {
            return (
                <TooltipPage
                    tooltipHeader="Return on ad spend (ROAS)"
                    tooltipMainContent={`The revenue you receive from your advertising investment. This is calculated by dividing sales attributed to your ads by your spend. Attribution varies by campaign type.`}
                    tooltipSubContent={`Learn about return on ad spend (ROAS)`}
                    tooltipCloseStatus={onCloseTooltip} />
            )
        }
    }

    const onCloseTooltip = (status: any) => {
        if (status) {
            setTooltipClose(true);
        }
    }

    const handleCustomSearch = (data: any) => {
        if (data?.trim()?.length > 0) {
            getSearchCampaigns(data);
        } else {
            const dateRangeData = {
                start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                end_date: moment(defaultEndDate).format("YYYY-MM-DD")
            }
            setSearchTermData("");
            const searchData = "";
            getPortfolioCamapaignsData(dateRangeData, searchData);
        }
    }

    const getSearchCampaigns = (data: any) => {
        const dateRangeData = {
            start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
            end_date: moment(defaultEndDate).format("YYYY-MM-DD")
        }
        setSearchTermData(data)
        getPortfolioCamapaignsData(dateRangeData, data);
    }

    const handleClickSearchWrong = (status: any) => {
        if (!status) {
            setSearchTermData("");
            const searchData = "";
            getPortfolioCamapaignsData(dateRangeData, searchData);
        }
    }

    const selectedGraphMetric = (data: any, index: number) => {
        setSelectedMetricIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const isMetricSelected = (metric: any) => {
        return (
            grpahCardsData.some((selectedMetric: any) => selectedMetric.card_title === metric.card_title)
        );
    };

    const onClickAddMetric = () => {
        setCanShowAddMetric(!canShowAddMetric);
    }

    const onSelectMetric = (data: any, index: any) => {
        const preparedData = grpahCardsData;
        preparedData.push(data);
        setGrpahCardsData([...preparedData]);
        setCanShowAddMetric(false);
    }

    function onRemoveMetric(data: any, index: number) {
        const preparedData = grpahCardsData;
        preparedData.splice(index, 1);
        setGrpahCardsData([...preparedData]);
    }

    const handleClose = () => {
        const tooltipStatus: any = tooltipClose;
        return tooltipStatus;
    }

    const handleCustomFilter = (filterData: any) => {
        if (filterData === "enabled") {
            const searchData = originalPortfolioInfoCampaignsData?.filter((el: any) => el?.state === "ENABLED");
            setPortfolioInfoCampaignsData({ ...portfolioInfoCampaignsData, data: searchData });
        } else if (filterData === "paused") {
            const searchData = originalPortfolioInfoCampaignsData?.filter((el: any) => el?.state === "PAUSED");
            setPortfolioInfoCampaignsData(searchData);
        } else if (filterData === "archived") {
            const searchData = originalPortfolioInfoCampaignsData?.filter((el: any) => el?.state === "ARCHIVED");
            setPortfolioInfoCampaignsData({ ...portfolioInfoCampaignsData, data: searchData });
        } else if (filterData === "all") {
            getPortfolioCamapaignsData(dateRangeData, searchTermData);
        } else {
            getPortfolioCamapaignsData(dateRangeData, searchTermData);
        }
    }

    const dateRangeFilterData = (data: any) => {
        setDateRangeData({ ...data })
        getPortfolioCamapaignsData(data, searchTermData);
        if (props?.dateRangeData) {
            props?.dateRangeData(data)
        }
    }

    return (
        <div className="container-fluid">
            <div className="row my-3">
                <div className="col-12 px-3">
                    <div className='card p-3 portfolio-settings-card rounded-0'>
                        <div className='d-flex justify-content-between py-1'>
                            <div className=''><h3 className='fs-21 fw-bold'>{portfoliosData?.portfolio_name}</h3></div>
                            <div className='explore-modal-navigation-text-color learn-more-text-color cursor-pointer logout fs-13' onClick={onClickModifyPortofolio}>Modify portfolio</div>
                        </div>
                        <div className='d-flex'>
                            <div className="fs-13"><span className="fw-bold">Status:</span><span>{portfoliosData?.state === "enabled" ? <span className='text-success ms-1'>Delivering</span> : <span className='text-dark ms-1'>Paused</span>}</span> </div>
                            <div className="fs-13 ms-4"><span className="fw-bold">Budget cap: </span><span className='text-dark ms-1'>{portfoliosData?.policy === "" ? "No budget cap" : portfoliosData?.policy === "Date range" ? "Date range" : "Recurring monthly"}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='px-3'>
                {showChart && (
                    <div className='row'>
                        <div className="col-12 d-flex flex-wrap justify-content-between col_padding">
                            {grpahCardsData?.map((data: any, index: number) => (
                                <div key={index} className={`card col-12 col-md-6 col_md_6 col-lg-2 col_lg_2 col_xl_2 col-xl-2 my-2 my-lg-0 text-bg-white rounded-0 ${index === selectedMetricIndex ? 'selected-"metric"-card' : ''}`} style={{ borderTop: `${index === selectedMetricIndex ? data?.border_color : ""}` }} onClick={() => selectedGraphMetric(data, index)}>
                                    <div className='card-header bg-white border-0 add_metrics-bg cursor-pointer'>
                                        <div className="d-flex justify-content-between">
                                            <div className="fs-13">{data?.card_title}
                                                <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                    <span className='ms-1 fs-15'>
                                                        <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <div className="fs-13 cross-icon cursor-pointer" onClick={() => onRemoveMetric(data, index)}>
                                                <i className="bi bi-x"></i>
                                            </div>
                                        </div>
                                        <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>}<span className='fs-11'>{data?.sub_title}</span></div>
                                    </div>
                                </div>
                            ))}
                            {grpahCardsData?.length < 5 && <div className={`${canShowAddMetric ? 'col-12 col-md-6 col-lg-2 col-xl-2 add-metric-border bg-white cursor-pointer' : 'card  py-3 col-12 col-md-6 col-lg-2 col-xl-2 border-1 border-dark card-border-style bg-white  rounded-0 cursor-pointer'}`} onClick={onClickAddMetric}>
                                <div className='card-header text-center bg-white border-0'>
                                    <span><img src={PlusIcon} /></span>
                                    add metric
                                    <div className={`${canShowAddMetric ? 'one' : ''}`}></div>
                                    <div className={`${canShowAddMetric ? 'two' : ''}`}></div>
                                </div>
                            </div>}
                        </div>
                        {canShowAddMetric && <div className='add-metric-list col-md-12 my-3'>
                            <div className='row add-metric-info '>
                                {metricCardsData?.map((data: any, index: number) => (
                                    <div key={index} className={`card col-12 col-md-4 col-lg-3 metrics-cols-extra-large metrics-cols-large my-2 col-xl-2 add_metrics-bg text-bg-white rounded-0 cursor-pointer col_margin ${isMetricSelected(data) ? "disabled-metric cursor-pointer-not-allowed" : ""}`} onClick={() => onSelectMetric(data, index)}>
                                        <div className='card-header bg-white border-0 add_metrics-bg'>
                                            <div className="d-flex justify-content-between">
                                                <div className="fs-13">{data?.card_title}
                                                    <Tooltip title={tooltipContent(data?.card_title)} placement="bottom" arrow className="custom-tooltip-position cursor-pointer" onClick={handleClose}>
                                                        <span className='ms-1 fs-15'>
                                                            <svg viewBox="0 0 512 512" width="12" height="12" className="fas fa-info-circle " data-fa-i2svg=""><path fill="#8290a4" d="M256 8c136.97 0 248 111.03 248 248S392.97 504 256 504 8 392.97 8 256 119.03 8 256 8Zm31 201.5h-62v186a31 31 0 1 0 62 0v-186ZM256 70a46.5 46.5 0 1 0 0 93 46.5 46.5 0 0 0 0-93Z"></path></svg>
                                                        </span>
                                                    </Tooltip>
                                                </div>
                                                <div className="fs-13 cross-icon">
                                                    <i className="bi bi-x"></i>
                                                </div>
                                            </div>
                                            <div>{data?.amount && <span className='fs-21'>{data?.amount}</span>}{!data?.amount && <span className='fs-21'>-</span>} <span className='fs-11'>{data?.sub_title}</span></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        {!canShowAddMetric && summaryReportsData &&
                            <ChartComponent chartData={summaryReportsData} />
                        }
                        {!summaryReportsData &&
                            <ChartComponent chartData={summaryReportsData} />
                        }
                    </div>
                )}
            </div>
            <div className="px-0">
                <CustomFilter
                    showChartStatus={getshowChatStatus}
                    customCreateButtonText='Create campaign'
                    customPlaceholder='Find a campaign'
                    customDateRangeText='Yesterday'
                    customPortfolioEditFilter={true}
                    onClickButton={(e) => onCreateButtonNavigation(e)}
                    onClickDotsButton={(e) => onCreateDotsButtonNavigation(e)}
                    handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
                    handleCustomFilter={(e) => handleCustomFilter(e)}
                    handleCustomSearch={(e) => handleCustomSearch(e)}
                    dateRangeFilterData={(e) => dateRangeFilterData(e)}
                    portfolioCampaignsExport={true}
                    campaignsDateRaneFilter={true}
                    dateRangeData={dateRangeData}
                    exportLoadingStatus={false}
                />
            </div>
            <div className='px-1'>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                }
                {!loading && (
                    <CustomTable
                        tableData={portfolioInfoCampaignsData}
                        TableCols={PortfolioInfoCampaignsDataGridCols}
                        tablefooterData={tablefooterData}
                        tableColumnNameStyle={true}
                        tableBottomText='Campaign status is current, but other campaign data could be delayed up to 12 hours.'
                        onActions={(e) => onActions(e)}
                        tableLoading={!loading}
                        onPageChange={e => onPageChange(e)}
                        rowsPerChangeData={(e) => rowsPerChangeData(e)}
                        isAPIPageNation={true}
                        pageData={pageData}
                        pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
                    />
                )}
            </div>
            {isAiActionsModal && (
                <div className={`modal modal-custom fade ${isAiActionsModal ? "show" : "false"}  ${isAiActionsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
                    <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                        <div className="modal-content mx-auto ">
                            <div className="modal-header border-bottom-0">
                                <h5 className="modal-title fs-17 fw-400">AI actions</h5>
                                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsAiActionsModal(false)}></button>
                            </div>
                            {loading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            }
                            {!loading &&
                                <div className='modal-body p-3 border-bottom'>
                                    <AiActionsTabs camapaignInfo={selectedCampaignData}
                                        campaigns={true}
                                        onTabActions={(e) => onTabActions(e)}
                                        aiActionRejectSubmit={(e) => getAiRejectSubmit(e)}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )}
            <div className='footer-container'>
                <CustomFooter />
            </div>
        </div>
    )
}