import { fontSize } from "@mui/system";
import { CustomTableCol } from "../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const CampaignsDataGridCols: CustomTableCol[] = [
  {
    title: "AI Actions",
    control: "actions",
    sortable: false,
    canShowColumn: true,
    isView: true,
    class: "col_table d-flex align-items-center",
    style: { width: "160px", left: 0, height: "110px" },
    isNotEyeIcon: true
  },
  {
    title: "Change Log",
    control: "AI_Actions_view",
    sortable: false,
    canShowColumn: true,
    class: "col_table",
    style: { width: "285px", left: 160, height: "110px" },
    isShowSwitch: false,
  },
  {
    title: "Active",
    control: "state",
    sortable: false,
    canShowColumn: true,
    class: "col_table d-flex align-items-center",
    style: { width: "58px", left: 444, height: "110px" },
    isShowSwitch: true,
  },
  {
    title: "Campaign ID",
    control: "campaign_id",
    sortable: true,
    canShowColumn: true,
    class: "col_table d-flex align-items-center",
    style: { width: "128px", left: 502, height: "110px", wordBreak: "break-all" },
  },
  {
    title: "Campaigns",
    control: "campaign_name",
    sortable: true,
    canShowColumn: true,
    class: "col_table d-flex align-items-center tb_left_border ",
    style: { width: "270px", left: 630, height: "110px", wordBreak: "break-all" },
    icon: info,
    infoIcon: true,
    isLink: true,
    tooltip: {
      text: "Campaigns",
    },
    isShowMenu: true,
  },
  {
    title: "Status",
    control: "state",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px", height: "110px" },
  },
  {
    title: "Type",
    control: "targeting_type",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "130px", height: "110px" },
  },
  {
    title: "Start date",
    control: "start_date",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "200px" },
    isDate: true,
  },
  {
    title: "End date",
    control: "end_date",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "200px" },
    isDate: true,
  },
  {
    title: "Budget",
    control: "budget",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "200px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Budget",
    },
  },
  {
    title: "Impressions",
    control: "impressions",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "120px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Impressions"
    },
  },
  {
    title: "Top-of-search IS",
    control: "topOfSearchImpressionShare",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "150px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Top of Search js"
    },
  },
  {
    title: "Clicks",
    control: "clicks",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Clicks"
    },
  },
  {
    title: "CTR",
    control: "clickThroughRate",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "CTR"
    },
  },
  {
    title: "Spend ",
    control: "cost",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "140px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns Spend"
    },
  },
  {
    title: "CPC",
    control: "costPerClick",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns CPC"
    },
  },
  {
    title: "Orders",
    control: "purchases30d",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns Orders"
    },
  },
  {
    title: "Sales",
    control: "sales30d",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    isShowRupee: true,
    tooltip: {
      text: "Campaigns Sales"
    },
  },
  {
    title: "Benchmark",
    control: "benchmark",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: false,
  },
  {
    title: "Qualifier",
    control: "qualifier",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: false,
  },
  {
    title: "ACOS",
    control: "acosClicks14d",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ACOS"
    },
  },
  {
    title: "ROAS",
    control: "roasClicks14d",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
    infoIcon: true,
    tooltip: {
      text: "Campaigns ROAS"
    },
  },
  {
    title: "Conversion rate",
    control: "conversion_rate",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "130px" },
    icon: info,
  },
  {
    title: "Total units",
    control: "unitsSoldClicks1d",
    sortable: true,
    canShowColumn: true,
    class: "pt-3 th_sticky",
    style: { width: "100px" },
    icon: info,
  },
];
