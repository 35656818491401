import { CustomTableCol } from "../../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const AdGroupsTargetingsDataGridCols: CustomTableCol[] = [
    {
        title: "AI Actions",
        control: "actions",
        sortable: false,
        canShowColumn: true,
        isView: true,
        class: "col_table",
        style: { width: "160px", left: "0px", height: "110px" },
        isNotEyeIcon: true
    },
    {
        title: "Change Log",
        control: "AI_Actions_view",
        sortable: false,
        canShowColumn: true,
        class: "col_table",
        style: { width: "290px", left: 160, height: "110px" },
        isShowSwitch: false,
    },
    {
        title: "Active",
        control: "state",
        sortable: false,
        canShowColumn: true,
        icon: true,
        class: "col_table",
        style: { width: "70px", left: "442px", height: "110px" },
        isShowSwitch: true,
    },
    {
        title: "Keyword",
        control: "keyword_text",
        sortable: true,
        canShowColumn: true,
        class: "col_table",
        style: { width: "175px", left: "513px", height: "110px" },
        icon: info,
    },
    {
        title: "Match Type",
        control: "match_type",
        sortable: true,
        canShowColumn: true,
        class: "col_table",
        style: { width: "117px", left: "688px", height: "110px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting Match Type",
        },
    },
    {
        title: "Status",
        control: "campaign_status",
        sortable: true,
        canShowColumn: true,
        class: "col_table",
        style: { width: "95px", left: "805px", height: "110px" },
    },
    {
        title: "Bid",
        control: "bid",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px", height: "110px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting bid",
        },
    },
    {
        title: "Impressions",
        control: "impressions",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        infoIcon: true,
        tooltip: {
            text: "Impressions"
        },
    },
    {
        title: "Top-of-search IS",
        control: "topOfSearchImpressionShare",
        sortable: true,
        canShowColumn: true,
        style: { width: "150px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Campaigns Top of Search js"
        },
    },
    {
        title: "Clicks",
        control: "clicks",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        infoIcon: true,
        tooltip: {
            text: "Ad Group Targeting Clicks",
        },
    },
    {
        title: "CTR",
        control: "clickThroughRate",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "CTR"
        },
    },
    {
        title: "Spend",
        control: "cost",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Targeting Spend",
        },
    },
    {
        title: "CPC",
        control: "costPerClick",
        sortable: true,
        canShowColumn: true,
        style: { width: "90px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Targeting CPC",
        },
    },
    {
        title: "Orders",
        control: "purchases30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group Orders",
        },
    },
    {
        title: "Sales",
        control: "sales30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Sales",
        },
    },
    {
        title: "Benchmark",
        control: "benchmark",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: false,
    },
    {
        title: "Qualifier",
        control: "qualifier",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: false,
    },
    {
        title: "ACOS",
        control: "acosClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ACOS",
        },
    },
    {
        title: "ROAS",
        control: "roasClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ROAS",
        },
    },
    {
        title: "Conversion rate",
        control: "conversion_rate",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px" },
        icon: info,
    },
    {
        title: "Total units",
        control: "unitsSoldClicks1d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px" },
        icon: info,
    },
];
