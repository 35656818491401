import { CustomTableCol } from "../../../../../components/custom-table/types";
const info = "/assets/images/svg_icons/info.svg";

export const AdGroupsAdsDataGridCols: CustomTableCol[] = [
    {
        title: "AI Actions",
        control: "actions",
        sortable: false,
        canShowColumn: true,
        isView: true,
        class: "col_table d-flex align-items-center",
        style: { width: "160px", left: "0px", height: "110px" },
        isNotEyeIcon: true
    },
    {
        title: "Change Log",
        control: "AI_Actions_view",
        sortable: false,
        canShowColumn: true,
        class: "col_table",
        style: { width: "285px", left: 160, height: "110px" },
        isShowSwitch: false,
    },
    {
        title: "Active",
        control: "state",
        sortable: false,
        canShowColumn: true,
        class: "col_table d-flex align-items-center",
        style: { width: "70px", left: "446px", height: "110px" },
        isShowSwitch: true,
    },
    {
        title: "Ads",
        control: "ad_group_name",
        sortable: true,
        canShowColumn: true,
        style: { width: "300px", left: "515px", height: "110px" },
        icon: info,
        isLink: true,
        class: "col_table d-flex align-items-center",
        infoIcon: true,
        tooltip: {
            text: "Ad Group ads",
        },
    },
    {
        title: "Status",
        control: "campaign_status",
        sortable: true,
        canShowColumn: true,
        class: "col_table d-flex align-items-center",
        style: { width: "85px", left: "815px", height: "110px" },
    },
    {
        title: "SKU / ASIN",
        control: "sku",
        sortable: true,
        canShowColumn: true,
        style: { width: "150px", height: "110px", paddingTop: "18px" },
    },
    {
        title: "Impressions",
        control: "impressions",
        sortable: true,
        canShowColumn: true,
        style: { width: "120px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Impressions"
        },
    },
    {
        title: "Top-of-search IS",
        control: "topOfSearchImpressionShare",
        sortable: true,
        canShowColumn: true,
        style: { width: "150px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Impressions"
        },
    },
    {
        title: "Clicks",
        control: "clicks",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Clicks"
        },
    },

    {
        title: "CTR",
        control: "clickThroughRate",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "CTR"
        },
    },

    {
        title: "Spend ",
        control: "cost",
        sortable: true,
        canShowColumn: true,
        style: { width: "140px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Spend",
        },
    },
    {
        title: "CPC",
        control: "costPerClick",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Campaigns CPC"
        },
    },
    {
        title: "Orders",
        control: "purchases30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group Orders",
        },
    },
    {
        title: "Sales",
        control: "sales30d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        isShowRupee: true,
        tooltip: {
            text: "Ad Group Sales",
        },
    },
    {
        title: "Benchmark",
        control: "benchmark",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: false,
    },
    {
        title: "Qualifier",
        control: "qualifier",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: false,
    },
    {
        title: "ACOS",
        control: "acosClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ACOS"
        },
    },
    {
        title: "ROAS",
        control: "roasClicks14d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
        infoIcon: true,
        tooltip: {
            text: "Ad Group ROAS"
        },
    },
    {
        title: "Conversion rate",
        control: "conversion_rate",
        sortable: true,
        canShowColumn: true,
        style: { width: "130px", paddingTop: "18px" },
        icon: info,
    },
    {
        title: "Total units",
        control: "unitsSoldClicks1d",
        sortable: true,
        canShowColumn: true,
        style: { width: "100px", paddingTop: "18px" },
        icon: info,
    },
];
