import React, { useEffect, useState } from "react";
import { CampaignsDataGridCols } from "./campaigns-data-grid-cols";
import { CustomTable } from "../../../components/custom-table";
import { useNavigate } from "react-router-dom";
import { CustomFooter } from "../../../components/footer/custom-footer";
import { GET_USER_CAMPAIGNS, UPDATE_CAMPAIGN_STATUS } from "../../../app/services/campaigns.service";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { CampaignData } from "../../../redux/actions";
import { useProfileId } from '../../../components/profile/index';
import { CustomFilter } from "../../../components/custom-filter/custom-filter";
import moment from "moment";
import { AI_SUGGESTION_ACCEPT, AI_SUGGESTION_REJECT } from "../../../app/services/ai-keys-update.service";
import { AiActionsTabs } from "../../../components/ai-actions-tabs/ai-actions-tabs";

interface Props {
  ProfileDataReducer?: any;
  profileData?: any;
  UserDataReducer?: any;
  userData?: any;
  CampaignDataReducer?: any;
  campaignData?: any;
  UserUuidDataReducer?: any;
  userUuidData?: any;
  camapignSearchData?: any
  dateRangeData?: (data: any) => void;
}

const Campaigns = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [exportCampaignsLoading, setExportCampaignsLoading] = useState<boolean>(false)
  const [pageData, setPageData] = useState({
    limit: '100',
    page: '1'
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [campaignsData, setCampaignsData] = useState<any>();
  const [originalCampaignData, setOriginalCampaignData] = useState<any>();
  const userUuid = localStorage.getItem('user_uuid');
  const profileID = localStorage.getItem('profile_id');
  const profileName = localStorage.getItem('profile_name');
  const [isAiActionsModal, setIsAiActionsModal] = useState<boolean>(false);
  const [isAiActionsHistoryModal, setIsAiActionsHistoryModal] = useState<boolean>(false);
  const [selectedCampaignData, setSelectedCampaignData] = useState<any>([]);
  const currentDate = new Date();
  const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate());
  const defaultEndDate = currentDate;
  const [searchTermData, setSearchTermData] = useState<any>("");
  const updateDateRange: any = localStorage?.getItem('updated_date_range');
  const updateData = JSON.parse(updateDateRange);
  const [dateRangeData, setDateRangeData] = useState<any>({
    start_date: updateData?.start_date ? updateData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
    end_date: updateData?.end_date ? updateData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
  });
  const { profileId }: any = useProfileId();

  useEffect(() => {
    if (profileId) {
      const dateRangeData = {
        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
      }
      getUserCampaigns(props?.userUuidData?.loginUserUuidData, profileId, dateRangeData
        , searchTermData);
    }
  }, [profileId])

  useEffect(() => {
    const sessionDateRange: any = localStorage?.getItem('updated_date_range')
    const dateRange = JSON.parse(sessionDateRange)
    if (dateRangeData) {
      setDateRangeData({ ...dateRangeData, start_date: dateRange?.start_date, end_date: dateRange?.end_date })
    }
  }, [])


  useEffect(() => {
    const userLoginUuid = props?.userUuidData?.loginUserUuidData;
    const changedProfileUserUuid = localStorage.getItem('profile_id');
    const sessionDateRange: any = localStorage?.getItem('updated_date_range')
    const dateRange = JSON.parse(sessionDateRange)
    const dateRangeData = {
      start_date: dateRange?.start_date ? dateRange?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
      end_date: dateRange?.end_date ? dateRange?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
    }
    if (userLoginUuid) {
      if (profileID) {
        getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, searchTermData);
      }
    }
  }, [props?.userUuidData?.loginUserUuidData]);

  const tablefooterData = [
    {
      campaign_name: `Totals for ${campaignsData?.total_count ? campaignsData?.total_count : 0} Campaigns`,
      impressions: "",
      metric_clicks: "",
      metric_impressions: "",
    },
  ];

  const tableBottomTextData = 'Campaign status is current, but other campaign data could be delayed up to 12 hours.';

  const getUserCampaigns = (userUuid: any, profileId: any, data: any, searchData: any) => {
    const sessionDateRange: any = localStorage?.getItem('updated_date_range');
    const dateRange = JSON.parse(sessionDateRange);
    const preparedData: any = {
      user_uuid: userUuid,
      profile_id: profileId,
      start_date: dateRange?.start_date ? dateRange?.start_date : data?.start_date,
      end_date: dateRange?.end_date ? dateRange?.end_date : data?.end_date,
      is_report_data: true,
      campaign_name: searchData
    }
    let page;
    if (searchData) {
      page = { limit: "100", page: "1" }
    } else if (searchData === "") {
      page = pageData
    }
    setLoading(true);
    GET_USER_CAMPAIGNS({ ...preparedData, ...page }).then((res) => {
      if (res.data.status === "error" || res?.data?.code == 500) {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          const response = res.data?.data;
          response?.data?.forEach((element: any) => {
            element.metric_clicks = element?.metricsData?.clicks
            element.metric_impressions = element?.metricsData?.impressions
          });

          for (let i = 0; i < response?.data?.length; i++) {
            response.data[i].impressions = response.data[i]?.metricsData ? response.data[i]?.metricsData?.impressions?.toLocaleString('en-IN') : '-';
            response.data[i].clicks = response.data[i]?.metricsData ? response.data[i]?.metricsData?.clicks?.toLocaleString('en-IN') : '-';
            response.data[i].clickThroughRate = response?.data[i]?.metricsData ? getCTRData(parseFloat(response?.data[i]?.metricsData?.clickThroughRate)?.toFixed(2)) : '-';
            response.data[i].cost = response.data[i]?.metricsData ? (getRupeeData(response.data[i]?.metricsData?.cost)) : '-';
            response.data[i].costPerClick = response.data[i]?.metricsData ? getRupeeData(response.data[i]?.metricsData?.costPerClick) : '-';
            response.data[i].purchases30d = response.data[i]?.metricsData ? response.data[i]?.metricsData?.purchases30d === undefined ? "-" : response.data[i]?.metricsData?.purchases30d?.toLocaleString('en-IN') : '-';
            response.data[i].sales30d = response.data[i]?.metricsData ? (getRupeeData(response.data[i]?.metricsData?.sales30d)) : '-';
            response.data[i].acosClicks14d = response.data[i]?.metricsData ? (getPercentageData(response.data[i]?.metricsData?.acosClicks14d)) : '-';
            response.data[i].roasClicks14d = isNaN(parseFloat(response.data[i]?.metricsData?.roasClicks14d))
              ? "-"
              : parseFloat(response.data[i]?.metricsData?.roasClicks14d)?.toFixed(2);
            response.data[i].topOfSearchImpressionShare = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.topOfSearchImpressionShare) : "-";
            response.data[i].conversion_rate = response.data[i]?.metricsData ? getPercentageData(response.data[i]?.metricsData?.conversion_rate) : "-";
            response.data[i].unitsSoldClicks1d = response.data[i]?.metricsData?.unitsSoldClicks1d !== undefined
              ? response.data[i]?.metricsData?.unitsSoldClicks1d
              : "-";
            response.data[i].benchmark = response.data[i].benchmark ? response.data[i].benchmark : "-";
            response.data[i].qualifier = response.data[i].qualifier ? response.data[i].qualifier : "-";
          }
          setOriginalCampaignData(response?.data)
          setCampaignsData(response);
          setLoading(false);
        }
      }
    });
  }

  const getCTRData = (data: any) => {
    if (data === "NaN") {
      return "-"
    } else if (data === undefined) {
      return "-";
    } else {
      return data + "%";
    }
  }

  const getPercentageData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data === 0) {
      return "0";
    } else if (data === undefined) {
      return "-";
    } else {
      return data?.toFixed(2) + '%';
    }
  }

  const getRupeeData = (data: any) => {
    if (data === "-") {
      return "-";
    } else if (data == 0) {
      return 0;
    } else if (data === undefined) {
      return "-";
    } else {
      const preparedData = parseFloat(data?.toFixed(2))?.toLocaleString('en-IN');
      return `${preparedData}`
    }
  }

  const onActions = (data: any) => {
    if (data.type === "link") {
      if (props?.campaignData) {
        dispatch(props.campaignData(data.data));
      }
      const campaignData = JSON.stringify(data?.data);
      localStorage.setItem('campaign_data', campaignData);
      localStorage.setItem("campaign_id", data.data.campaign_id)
      navigate(`/dashboard/campaign/ad-groups`)
    } else if (data.type === "status") {
      const status: any = data?.data?.statusChecked ? "ENABLED" : "PAUSED";
      const toggleStatusPreparedData: any = {
        user_uuid: userUuid,
        profile_id: profileID,
        campaign_id: data.data.campaign_id,
        state: status
      }
      UPDATE_CAMPAIGN_STATUS(toggleStatusPreparedData).then((res) => {
        if (res.data.status === "error" || res?.data?.code == 500) {
          toast.error(res.data.message);
          setLoading(false);
        } else {
          if (res) {
            const response = res.data.data;
            toast.success(res.data.message);
            const dateRangeData = {
              start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
              end_date: moment(defaultEndDate).format("YYYY-MM-DD")
            }
            getUserCampaigns(userUuid, profileID, dateRangeData, searchTermData)
            setLoading(false);
          }
        }
      });
    } else if (data.type === "edit camapign") {
      localStorage.setItem("campaign_id", data?.data?.campaign_id);
      navigate("/dashboard/campaign/settings");
    } else if (data?.type === "view") {
      setSelectedCampaignData(data?.data);
      setIsAiActionsModal(true);
    } else if (data?.type === "AI Accept") {
      onAISugesstionAccept(data?.data, data?.type);
    } else if (data?.type === "AI Reject") {
      setSelectedCampaignData(data?.data);
      setIsAiActionsModal(true);
    } else if (data?.type === "viewHistory") {
      setSelectedCampaignData(data?.data);
      setIsAiActionsHistoryModal(true);
    }
  }

  const onCreateButtonNavigation = (data: string) => {
    const navigationData = data;
    if (navigationData) {
      navigate("/dashboard/campaign-type")
    }
  }

  const onPageChange = (newPage: any) => {
    const page = pageData;
    page.page = (newPage + 1).toString()
    setPageData({ ...page });
    const dateRangeData = {
      start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
      end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    }
    getUserCampaigns(userUuid, profileID, dateRangeData, searchTermData)

  }

  const rowsPerChangeData = (data: any) => {
    const page = pageData;
    page.page = '1'
    page.limit = data.toString();
    setPageData({ ...page });
    const dateRangeData = {
      start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
      end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    }

    getUserCampaigns(userUuid, profileID, dateRangeData, searchTermData)
  }

  const handleCustomSearch = (data: any) => {
    if (data?.trim()?.length > 0) {
      getSearchCampaigns(data);
    } else {
      const userLoginUuid = props?.userUuidData?.loginUserUuidData;
      const changedProfileUserUuid = localStorage.getItem('profile_id');
      const dateRangeData = {
        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
      }
      setSearchTermData("");
      const searchData = "";
      if (userLoginUuid) {
        if (profileID) {
          getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, searchData);
        }
      }
    }
  }

  const getSearchCampaigns = (data: any) => {
    const userLoginUuid = props?.userUuidData?.loginUserUuidData;
    const changedProfileUserUuid = localStorage.getItem('profile_id');
    const dateRangeData = {
      start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
      end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    }
    setSearchTermData(data)
    if (userLoginUuid) {
      if (profileID) {
        getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, data);
      }
    }
  }

  const handleClickSearchWrong = (status: any) => {
    if (!status) {
      const userLoginUuid = props?.userUuidData?.loginUserUuidData;
      const changedProfileUserUuid = localStorage.getItem('profile_id');
      const dateRangeData = {
        start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: moment(defaultEndDate).format("YYYY-MM-DD")
      }
      setSearchTermData("");
      const searchData = "";
      if (userLoginUuid) {
        if (profileID) {
          getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, searchData);
        }
      }
    }
  }

  const handleCustomFilter = (filterData: any) => {
    const dateRangeData = {
      start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
      end_date: moment(defaultEndDate).format("YYYY-MM-DD")
    }
    if (filterData === "enabled") {
      const searchData = originalCampaignData?.filter((el: any) => el?.state === "ENABLED");
      setCampaignsData(searchData);
    } else if (filterData === "paused") {
      const searchData = originalCampaignData?.filter((el: any) => el?.state === "PAUSED");
      setCampaignsData(searchData);
    } else if (filterData === "archived") {
      const searchData = originalCampaignData?.filter((el: any) => el?.state === "ARCHIVED");
      setCampaignsData(searchData);
    } else if (filterData === "all") {
      const userLoginUuid = props?.userUuidData?.loginUserUuidData;
      const changedProfileUserUuid = localStorage.getItem('profile_id');
      if (userLoginUuid) {
        if (profileID) {
          getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, searchTermData);
        }
      }
    } else {
      const userLoginUuid = props?.userUuidData?.loginUserUuidData;
      const changedProfileUserUuid = localStorage.getItem('profile_id');
      if (userLoginUuid) {
        if (profileID) {
          getUserCampaigns(userLoginUuid, changedProfileUserUuid ? changedProfileUserUuid : profileID, dateRangeData, searchTermData);
        }
      }
    }
  }

  const handleCampaignExport = (status: any) => {
    if (status) {
      const profileId = localStorage.getItem('profile_id');
      const updateDateRange: any = localStorage?.getItem('updated_date_range');
      const updateData = JSON.parse(updateDateRange);
      const dateRangeData = {
        start_date: updateData?.start_date ? updateData?.start_date : moment(defaultStartDate).format("YYYY-MM-DD"),
        end_date: updateData?.end_date ? updateData?.end_date : moment(defaultEndDate).format("YYYY-MM-DD")
      };
      const date = moment(currentDate).format("DD-MM-YYYY")
      const preparedData: any = {
        user_uuid: userUuid,
        profile_id: profileId,
        start_date: updateData?.start_date ? updateData?.start_date : dateRangeData?.start_date,
        end_date: updateData?.end_date ? updateData?.end_date : dateRangeData?.end_date,
        campaign_name: ""
      }
      setExportCampaignsLoading(true);
      fetch(
        "https://advise.mrdev.co.in/api/userCampaign/exportCampaignsData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

          },
          body: JSON.stringify(preparedData),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = `${profileName}_campaigns_${date}.xlsx`;
          downloadLink.click();

          window.URL.revokeObjectURL(downloadLink.href);
          setExportCampaignsLoading(false);
          toast.success(
            "Campaigns downloaded successfully", { autoClose: 2000 }
          );
        })
        .catch((error) => {
          setExportCampaignsLoading(false);
          console.error("Error downloading file:", error);
          toast.error(
            "An error occurred while exporting campaigns. Please try again later.", { autoClose: 2000 }
          );
        });
    }
  }

  const dateRangeFilterData = (data: any) => {
    setDateRangeData({ ...data })
    const userLoginUuid = props?.userUuidData?.loginUserUuidData;
    const changedProfileUserUuid = localStorage.getItem('profile_id');

    getUserCampaigns(userLoginUuid, changedProfileUserUuid, data, searchTermData)
    if (props?.dateRangeData) {
      props?.dateRangeData(data)
    }
  }

  const onRefreshCampaignsData = (status: any) => {
    if (status) {
    }
  }

  const getSuggestion = (data: any, type: any) => {
    if (type === "AI Accept") {
      return data?.ai_suggestion?.suggestion ? data?.ai_suggestion?.suggestion : ""
    } else if (type === "Manual Accept") {
      if (data?.action_type === "increase") {
        return `${data?.action_type} budget by ${data?.action_value}%`
      } else if (data?.action_type === "reduce") {
        return `${data?.action_type} budget by ${data?.action_value}%`
      }
    }
  }

  const onAISugesstionAccept = (aiAcceptData: any, acceptType: any) => {
    console.log('aiAcceptData', aiAcceptData);
    
    const preparedData = {
      user_uuid: userUuid,
      type: "CAMPAIGNS",
      profile_id: profileID,
      campaign_id: acceptType === "Manual Accept" ? aiAcceptData?.campaign_data?.campaign_id : aiAcceptData?.campaign_id,
      suggestion: getSuggestion(aiAcceptData, acceptType),
      uuid: aiAcceptData?.ai_suggestion?.uuid
    }
    setLoading(true)
    AI_SUGGESTION_ACCEPT(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code == 500) {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          setIsAiActionsModal(false);
          toast.success(res.data.message);
          const dateRangeData = {
            start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
            end_date: moment(defaultEndDate).format("YYYY-MM-DD")
          }
          const profileData = localStorage?.getItem('profile_id');
          getUserCampaigns(props?.userUuidData?.loginUserUuidData, profileId ? profileId : profileData, dateRangeData, searchTermData)
          setLoading(false);
        }
      }
    });
  }

  const handleAiRejectSubmit = (data: any) => {
    const preparedData = {
      user_uuid: userUuid,
      uuid: data?.uuid ? data?.uuid : "",
      rejection_message: data?.rejection_message
    }
    setLoading(true)
    AI_SUGGESTION_REJECT(preparedData).then((res) => {
      if (res.data.status === "error" || res?.data?.code == 500) {
        toast.error(res.data.message);
        setLoading(false);
      } else {
        if (res) {
          const dateRangeData = {
            start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
            end_date: moment(defaultEndDate).format("YYYY-MM-DD")
          }
          const profileData = localStorage?.getItem('profile_id');
          getUserCampaigns(props?.userUuidData?.loginUserUuidData, profileId ? profileId : profileData, dateRangeData, searchTermData)
          setIsAiActionsModal(false);
          setLoading(false);
        }
      }
    });
  }

  const onTabActions = (data: any) => {
    if (data?.type === "AI Accept") {
      onAISugesstionAccept(data?.data, data?.type);
    } else if (data?.type === "Manual Accept") {
      data.data.campaign_data = data?.campaignInfo
      onAISugesstionAccept(data.data, data?.type)
    } else if (data?.type === "navigate") {
      navigate("/dashboard/configuration")
    }
  }

  const getAiRejectSubmit = (aiRejectData: any) => {
    handleAiRejectSubmit(aiRejectData)
  }


  const getAiRejectCancel = (aiRejectData: any) => {
    setIsAiActionsModal(false);
  }

  return (
    <div className="container-fluid px-0">
      <div className="">
        <CustomFilter
          customCreateButtonText="Create campaign"
          customPlaceholder="Find a campaign"
          customDateRangeText="Aug 14 - Aug 22, 2023"
          campaignsFilter={true}
          showChartStatus={true}
          exportData={"campaigns"}
          exportTableData={campaignsData}
          campaignsDateRaneFilter={true}
          onClickButton={(e) => onCreateButtonNavigation(e)}
          handleClickSearchWrong={(e) => handleClickSearchWrong(e)}
          handleCustomFilter={(e) => handleCustomFilter(e)}
          handleCustomSearch={(e) => handleCustomSearch(e)}
          dateRangeFilterData={(e) => dateRangeFilterData(e)}
          onRefreshCampaignsData={(e) => onRefreshCampaignsData(e)}
          campaignExport={(e) => handleCampaignExport(e)}
          exportLoadingStatus={exportCampaignsLoading}
          dateRangeData={dateRangeData}
        />
      </div>
      {loading &&
        <div className="text-center p-5">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      }
      {!loading &&
        <div className="px-0">
          <CustomTable
            TableCols={CampaignsDataGridCols}
            tableData={campaignsData}
            tablefooterData={tablefooterData}
            tableScroll={true}
            tableBottomTextStatus={true}
            tableLoading={!loading}
            tableBottomText={tableBottomTextData}
            onActions={(e) => onActions(e)}
            onPageChange={e => onPageChange(e)}
            rowsPerChangeData={(e) => rowsPerChangeData(e)}
            isAPIPageNation={true}
            pageData={pageData}
            pageCount={{ sorting_field: 'cost', sorting_type: 'asc' }}
            tableColumnMarginLeft={900}
          />
        </div>
      }
      {isAiActionsModal && (
        <div className={`modal modal-custom fade ${isAiActionsModal ? "show" : "false"}  ${isAiActionsModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
            <div className="modal-content mx-auto ">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title fs-17 fw-400">AI actions</h5>
                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsAiActionsModal(false)}></button>
              </div>
              {loading &&
                <div className="text-center p-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              {!loading &&
                <div className='modal-body p-3 border-bottom'>
                  <AiActionsTabs camapaignInfo={selectedCampaignData}
                    campaigns={true}
                    onTabActions={(e) => onTabActions(e)}
                    aiActionRejectSubmit={(e) => getAiRejectSubmit(e)}
                    aiActionRejectCancel={(e) => getAiRejectCancel(e)}
                    isShowReject={true}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      )}

      {isAiActionsHistoryModal && (
        <div className={`modal modal-custom fade ${isAiActionsHistoryModal ? "show" : "false"}  ${isAiActionsHistoryModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
            <div className="modal-content mx-auto ">
              <div className="modal-header border-bottom-0">
                <h5 className="modal-title fs-17 fw-400">AI actions history</h5>
                <button type="button" className="btn-close fs-13" data-bs-dismiss="modal" aria-label="Close" onClick={() => setIsAiActionsHistoryModal(false)}></button>
              </div>
              {loading &&
                <div className="text-center p-5">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              }
              {!loading &&
                <div className='modal-body p-3 border-bottom'>
                  <table>
                    <thead>
                      <tr>
                        <th>Suggestion</th>
                        <th>Rejection message</th>
                        <th>Applied date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedCampaignData?.last_applied_records?.map((data: any, index: number) => {
                        return <tr key={index}>
                          <td>{data?.suggestion}</td>
                          <td>{data?.rejection_message}</td>
                          <td>{data?.applied_date ? moment(data?.applied_date).format('MM-DD-YYYY HH:MM') : ''}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        </div>
      )}
      <div className="footer-container">
        <CustomFooter />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    profileData: state.ProfileDataReducer,
    userData: state.UserDataReducer,
    userUuidData: state.UserUuidDataReducer
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    campaignData: (data: any) => dispatch(CampaignData(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(Campaigns);
export { connectedNavBar as Campaigns };